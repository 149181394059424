import './index.css';
import Main from './comp/main/main';
import Apply from './comp/apply/main';
import Announcement from './comp/announcement/main';
import PostProposal from './comp/postproposal/main';
import MainAuthentication from './comp/main/authentication';
import ApplyAuthentication from './comp/apply/authentication';
import ProposalAuthentication from './comp/postproposal/authentication';
import UserPage from './comp/user-page/main';
import AnnouncementDetails from "./comp/announcement/AnnouncementDetails";
import ProposalDetails from './comp/main/mobileProposalDetails';
import DesktopProposalDetails from './comp/main/desktopProposalDetails';
import ScrolltoTop from './ScrolltoTop';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
// import ApplyResearchDescription from './comp/apply/expectation';
import axios from 'axios';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from './firebase/firebaseConfig';
import DesktopProposalDetailsDefault from './comp/main/defaultDesktopProposalDetails';
import SingleProposalDetail from './comp/main/singleProposal';
// import ViewApplication from './comp/user-page/viewApplication';
import AboutUs from './comp/about-us/about-us';

function App() {
  const [isMobile, setMobile] = useState(window.innerWidth < 640);
  const [isDesktop, setDesktop] = useState(window.innerWidth >= 640);
  const [project, setProject] = useState([]) // for main
  const [page, setPage] = useState(1);
  const [projectLoading, setProjectLoading] = useState(true)
  const pageSize = 5;
  const [user, loading, error] = useAuthState(auth)


  const getProject = async () => {
    setProjectLoading(true)
    try {
        const response = await axios.post(`${process.env.REACT_APP_SERVERURL}/project/get_projects`, {data: {}})
        let displayData = response.data.data
        setProject(displayData) // for main 
    } catch (err) {
      console.log(err)
        console.error('Error: ', err)
    }  finally {
      setProjectLoading(false);
    }
  }  
  
  {/* Resize methods 1. define screen size 2. update on window size */}
  const updateMedia = () => {
    setMobile(window.innerWidth < 640)
    setDesktop(window.innerWidth >= 640)
  };

  useEffect(() => {
    // life cycling
    getProject()
    window.addEventListener('resize', updateMedia)
    return () => {
      window.removeEventListener('resize', updateMedia)
    };
  }, [])

  // sorting by application posted date
  const sortedProjects = project?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

  // as the user clicks the load button, it will load (page * pageSize) more data
  const pagedProjects = sortedProjects?.slice(0, page * pageSize);

  return (
    <Router>
        {/* in mobile showing the content from the of page */}
      {isMobile && <ScrolltoTop/>}
      <div className="App">
        <Routes>
          {/* loading first, then check if user logged in or not. If user logged in then shows the landing page if not then show admin page  -- remove admin */}
          <Route path="/" element={loading ? <div></div> : <Main sortedProjects={pagedProjects} setPage={setPage} page={page} pageSize={pageSize} total={sortedProjects.length} /> }>
            {isDesktop && <Route path=":id" element={<DesktopProposalDetails />} />}
            {isDesktop && <Route path="home" element={< DesktopProposalDetailsDefault/>} />}
          </Route>

          {/* About Us Page */}
          <Route path="/about-us" element={< AboutUs />} />

          {/* Single page proposal */}
          <Route path="/proposal/:id" element={<SingleProposalDetail sortedProjects={sortedProjects} /> } />

          {isMobile && (
            <Route path="/:id" element={<ProposalDetails sortedProjects={pagedProjects} />} />
          )}
          <Route path="/apply/:id" element={<Apply sortedProjects={pagedProjects} projectLoading={projectLoading} />} />
          <Route path="/announcement" element={<Announcement />} /> 
          <Route path="/announcement/:postId" element={<AnnouncementDetails />} />
          <Route path="/postproposal" element={<PostProposal />} /> 
          <Route path="/auth" element={<MainAuthentication />} />
          <Route path="/auth/apply" element={<ApplyAuthentication />} /> 
          <Route path="/auth/proposal" element={<ProposalAuthentication />} /> 
          <Route path="/user" element={<UserPage allProject={project} />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;

// logic for routing user page 
// 1. query all projects from App.js using get_project POST -> pass it to UserPage 
// 2. useParams from User Page, unique id has to be ownderuid