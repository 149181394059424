import React, { useState } from 'react';

function Dropdown() {
  const [ascending, setAscending] = useState(true);

  return (
    <div className="group sm:ml-[0rem]">
      <div className="flex items-center">
        <button id="dropdownDefaultButton" data-dropdown-toggle="dropdown" className="w-[114px] h-8 rounded-lg border border-zinc-500 px-2 gap-2 items-center inline-flex" type="button">
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.75 12.1274L3.6225 8.99988L2.5575 10.0574L6.75 14.2499L15.75 5.24988L14.6925 4.19238L6.75 12.1274Z" fill="#1D192B"/>
          </svg>
          <div className="text-center text-gray-900 text-sm font-medium font-roboto leading-tight tracking-tight">Sort by</div>
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.25 7.5L9 11.25L12.75 7.5H5.25Z" fill="#1D192B"/>
          </svg>
        </button>
      </div>

      <div id="dropdown" className="z-10 hidden group-hover:block absolute bg-white divide-y divide-gray-100 rounded-lg shadow w-[7.125rem] bg-white">
        <ul className="py-2 text-sm dark:text-black" aria-labelledby="dropdownDefaultButton">
          <li>
            <div className="block flex flex-row px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white" onClick={() => setAscending(true)}>
              Ascending
            </div>
          </li>
          <li>
            <div className="block flex flex-row px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white" onClick={() => setAscending(false)}>
              Descending
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Dropdown;
