// Similar to proposal_data 
// I think this was made for testinng purpose. 
// Dont really understand what this code does, we need to talk -Jinwoo 

import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import Header from "../main/header";

function SingleProposalDetail({ sortedProjects }) {
    const { id } = useParams()

    const proj_list = sortedProjects?.map((project) => project) // mapping through each data
    const project = proj_list.find((project) => project.id === parseInt(id)) // chose a specific data by id

    // loading is happening here
    if (!project) {
        return <div>loading ... </div>; 
    }

    const convertMMDDYY = function unix_timeConverter(unix_timestamp) {
        var a = new Date(unix_timestamp * 1000)
        var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
        var year = a.getFullYear();
        var month = months[a.getMonth()];
        var date = a.getDate();                             
        var time = month + ' '+ date + ', ' + year;
        return time;
    }
    
    const projectDays = function calc_project_days() {
        // Assuming first_project.start_date and first_project.end_date are Date objects
        const differenceInMilliseconds = project.end_date - project.start_date
        const differenceInDays = differenceInMilliseconds / 86400;
        return differenceInDays;
    }

    return (
        <div className="flex flex-col md:h-screen">
            <div>
            <Header />
            <div className="flex flex-col items-center px-[3rem]">
                <div className="flex flex-col justify-center w-full mt-[1rem]">
                    <div className="post-header my-5">
                        <p className="pb-[0.5rem]">Posted {projectDays()} Days Ago</p>
                        <h1 className="text-5xl roboto font-bold">{project.title}</h1>
                    </div>
                    <p className="text-2xl pt-[1rem]">Summary:</p>
                    <p className="texts pb-[0.5rem]"> 
                        {JSON.parse(project.topics).map((skill, index) => (
                            <li key={index}>{skill}</li>
                        ))}</p>
                    {
                        project.description.split('\n').map((paragraph, index) => (
                            <p className="texts pb-[0.5rem]" key={index}>{paragraph}</p>
                        ))
                    } 
                    
                    <p className="text-2xl pt-[1rem]">Knowledge, Skills & Abilities:</p>
                    <p className="texts pb-[0.5rem]"> 
                        {JSON.parse(project.skills).map((skill, index) => (
                            <li key={index}>{skill}</li>
                        ))}</p>
                        {project.required_qualifications}

                    <p className="text-2xl pt-[1rem]">Preferred Qualifications:</p>
                    <p className="texts pb-[0.5rem]"> 
                        {project.notes}
                    </p>
                    <p className="text-2xl pt-[1rem]">Research Outcome / Final Delivery:</p>
                    <p className="texts pb-[0.5rem]"> 
                        {JSON.parse(project.final_delivery_types).map((skill, index) => (
                            <li key={index}>{skill}</li>
                        ))}
                    <p className="texts pb-[0.5rem]">
                        {project.final_delivery_description}
                    </p>
                    </p>
                </div>
            </div>
            <footer className="footer">
                <div className='footer-text text-center'>
                    &copy; 2023 Layrd. All Rights Reserved. 
                </div>
            </footer>
            </div>
        </div>
        
    );
}

export default SingleProposalDetail;
