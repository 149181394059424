import React from 'react';

const RegButton = ({ text, onClick, form = '' ,type = 'button', additionalClasses = '' }) => {
    return (
        <button
            type={type}
            onClick={onClick}
            form={form}
            className={`w-[84px] h-10 bg-theme-purple hover:bg-[#584C7A] rounded-[100px] text-center text-white text-sm font-medium font-roboto ${additionalClasses}`}
        >
            {text}
        </button>
    );
};

export default RegButton;
