import React from "react";
import { useParams } from "react-router-dom";
import DemoPost from "./post_data";
import Header from "../main/header";

function AnnouncementDetails() {
  const { postId } = useParams();
  const announcement = DemoPost().find((post) => post.postId === postId);

  if (!announcement) {
    return <div>Announcement not found</div>; 
  }

  return (
    <div className="max-x-xl w-full">
        < Header />
        <div className="flex flex-col items-center h-screen">
            <div className="flex flex-col justify-center items-start w-[80rem] mt-[5rem]">
                <div className="post-header my-10">
                    <p className="pb-[0.5rem]">{announcement.written_date}</p>
                    <h1 className="text-5xl roboto font-bold">{announcement.title}</h1>
                </div>
                {
                    announcement.content.split('\n').map((paragraph, index) => (
                        <p className="texts pt-[1rem]" key={index}>{paragraph}</p>
                    ))
                }
            </div>
        </div>
        <footer>
                <div className='footer-text'>
                    &copy; 2023 Layrd. All Rights Reserved. 
                </div>
        </footer>    
      </div>
  );
}

export default AnnouncementDetails;
