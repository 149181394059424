import logo from '../../img/layrd-logo.png';
import React, { useState, useEffect, useLayoutEffect } from 'react';
import { auth } from '../../firebase/firebaseConfig';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Link } from 'react-router-dom';

//Main Header
function LoginHeader() {
    const [user, loading, error] = useAuthState(auth);
    const [open, setOpen] = useState(false);

    const [isMobile, setMobile] = useState(false);
    const [isDesktop, setDesktop] = useState(false);

    // Fetch the first project from the sortedProjects
    const updateMedia = () => {
        setMobile(window.innerWidth < 640);
        setDesktop(window.innerWidth >= 640);
    };

    useLayoutEffect(() => {
        updateMedia();
    }, []);
    
    return (
        <>
            {/* This example requires Tailwind CSS v2.0+ */}
            <div className="relative bg-white z-[1]">
                <div className="max-w mx-auto px-4 sm:px-6">
                    <div className="flex justify-between items-center border-b-2 border-gray-100 py-2 md:justify-start md:space-x-10"> {/*padding change */}
                        <div className="flex justify-start mx-[1rem] lg:w-0 lg:flex-1">
                            <div className="flex mr-[3rem] text-xl">
                                <div className=''>  {/*more implementation will be made when logo is updated*/}
                                {/* when it is in mobile, set the path to "/" but if it is in desktop size change to the following: */}
                                                                
                                {isDesktop && <Link to={`/`}>
                                    <img src={logo} alt="logo" className='h-12 my-auto' /> 
                                </Link>}
                                
                                {isMobile && <Link to={"/"}>
                                    <img src={logo} alt="logo" className='h-12 my-auto'/> 
                                </Link>}
                               
                                </div>
                                <div className="hidden md:flex">
                                    <ul className="flex flex-row list-none">
                                        {/*  
                                        Commented out for later implementation 
                                        <li className="ml-2 p-2 my-auto">
                                            <Link to="/stat">
                                                Stat
                                            </Link>  
                                        </li>
                                        <li className='p-2 my-auto'>
                                            <Link to="/announcement">
                                                Announcement
                                            </Link>
                                        </li>  
                                        */}
                                        <li className='bg-white text-lg hover:bg-purple-400  hover:text-white hover:border-black text-gray-800 py-2 px-3 border font-semibold border-gray-400 rounded shadow ml-3 my-auto type=postproposal'>
                                            <a href='https://blockresearch.gitbook.io/blockresearch/'  target="_blank" rel="noreferrer">Docs</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/* Hamburger button */}
                        <div className="-mr-2 -my-2 md:hidden">
                            <button
                                type="button"
                                className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                                onClick={() => setOpen(!open)}
                            >
                                <span className="sr-only">Open menu</span>
                                {/* Heroicon name: outline/menu */}
                                <svg
                                className="h-6 w-6"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                aria-hidden="true"
                                >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M4 6h16M4 12h16M4 18h16"
                                />
                                </svg>
                            </button>
                        </div>
                        
                        {/* <nav className="hidden md:flex space-x-10">
                        <div className="relative">
                            hello
                        </div>
                        </nav> */}
                        
                        <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0 my-auto">
                            <ul className="flex flex-row list-none mx-3">
                                <li className='bg-white text-lg hover:bg-purple-400 hover:text-white hover:border-black text-gray-800 py-2 px-3 border font-semibold border-gray-400 rounded shadow mr-2 my-auto type=postproposal'>
                                    {  
                                        loading ?
                                        (<div></div>) : 
                                        user ? 
                                        (<Link to="/postproposal">Post Proposal</Link>) :
                                        <Link to="/auth/proposal"> Post Proposal</Link>
                                    }
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                {/*
                    Mobile menu, show/hide based on mobile menu state.
                */}
                <div
                    className={
                        open
                        ? "opacity-100 scale-100 ease-out duration-200 absolute top-0 inset-x-0 p-2 transform origin-top-right md:hidden"
                        : "opacity-0 scale-95 absolute top-0 inset-x-0 p-2 transform origin-top-right hidden"
                    }
                >
                    <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
                        <div className="pt-5 pb-6 px-5">
                            <div className="flex items-center justify-between">
                                <Link to="/">
                                        <img src={logo} alt="logo" className='h-12'/> 
                                </Link>
                                <div className="-mr-2">
                                    <button
                                        type="button"
                                        className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 
                                        hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                                        onClick={() => setOpen(!open)}
                                    >
                                        <span className="sr-only">Close menu</span>
                                        {/* Heroicon name: outline/x */}
                                        <svg
                                        className="h-6 w-6"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        aria-hidden="true"
                                        >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="M6 18L18 6M6 6l12 12"
                                        />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                            <div className="mt-6">
                                <nav className="grid gap-y-8">
                                    <ul className="list-none space-y-[1rem]">
                                        <li
                                            href='https://blockresearch.gitbook.io/blockresearch/'
                                            target="_blank"
                                            className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
                                        >
                                            <span className="ml-3 text-base font-medium text-gray-900">
                                            Docs
                                            </span>
                                        </li>
                                        <li className='bg-white text-lg hover:bg-purple-400 hover:text-white hover:border-black text-gray-800 py-2 px-3 border font-semibold border-gray-400 rounded shadow mr-2 my-auto type=postproposal'>
                                        {  
                                            loading ?
                                            (<div></div>) : 
                                            user ? 
                                            (<Link to="/postproposal">Post Proposal</Link>) :
                                            <Link to="/auth/proposal"> Post Proposal</Link>
                                        }
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default LoginHeader;
