import { initializeApp } from "firebase/app";
import { getAuth, getIdTokenResult, GoogleAuthProvider, signInWithPopup, onAuthStateChanged } from "firebase/auth";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
  };


const app = initializeApp(firebaseConfig);
const auth = getAuth();

let provider = new GoogleAuthProvider();

// Check session expiration and show an alert
const ONE_HOUR = 3600000; // milliseconds

const checkSession = () => {
  const user = auth.currentUser;
  
  if (user) {
    getIdTokenResult(user).then((idTokenResult) => {
      const expTime = Date.parse(idTokenResult.expirationTime);
      const currentTime = new Date().getTime();
      //console.log('session start')
      //console.log(expTime)
      if ((expTime - currentTime) <= ONE_HOUR) {
        // console.log(expTime, currentTime)
        // alert("Your session is expired!"); // I think this is needed for user
      }
    });
  } else {
    console.log('catch reload')
  }
}

export { app, auth, provider, signInWithPopup, onAuthStateChanged, checkSession };

// later for customized session cookie - https://firebase.google.com/docs/auth/admin/manage-cookies
// Reference for id tokens.

// let idToken = null;
// let userCredential = null;
// let idTokenRefreshTime = null;

// async function getIdToken() {
//     if (idToken == null) {
//       await refreshIdToken();
//     } else if (Math.floor(Date.now() / 1000) - idTokenRefreshTime > 3600) {
//       await refreshIdToken(); 
//     }
//     return idToken;
// }

// async function refreshIdToken() {
//   idToken = await userCredential.user.getIdToken();
//   idTokenRefreshTime = Math.floor(Date.now() / 1000);
// }

// function setUserCred(u) {
//   userCredential = u;
// }

