function Notification( { message }) {

    return (
      <div className=" bottom-10 right-10 border-2-black justify-center p-4 bg-gray-400 font-semibold text-black max-w-2xl rounded">
        {message}
      </div>
    );
}

export default Notification; 

