export default function AnnouncementListBar() {
    return (
        <div className="annoucement-list flex w-[78rem] my-2 border-b-2 hover:border-double border-x-fuchsia-400 h-12 items-center">
            <div className="announcement-writer flex justify-center w-32">
                <h3 className="announcement-writer-list font-bold">Date</h3>
            </div>
            <div className="announcement-title flex justify-center w-[15rem]">
                <h1 className="announcement-title-list font-bold">Title</h1>
            </div>
            <div className="announcement-description flex justify-center w-[32rem]">
                <p className="announcement-description-list font-bold">Description</p>
            </div>
            <div className="announcement-view flex justify-center w-32">
                <p className="announcement-view-list font-bold">Writer</p>
            </div>
            <div className="announcement-comments flex justify-center w-[6rem]">
                <p className="announcement-comments-list font-bold">View</p>
            </div>
            <div className="announcement-share w-[8rem] flex justify-center">
                <p className="announcement-share-list font-bold"></p>
            </div>
        </div>
    )
}

