import { auth, provider, signInWithPopup } from '../../firebase/firebaseConfig';
import  '../../styles/main.css';
import { Link, useNavigate } from "react-router-dom"; 
import ProposalHeader from './proposal-header';

// frist time user (logic)
function ProposalAuthentication(){ 
    const navigate = useNavigate();

    const signInWithGoogle = async () => {
        try {
            let user_cred = await signInWithPopup(auth, provider);
            //console.log(user_cred);
            let id_token = await user_cred.user.getIdToken();
            //console.log(id_token);

            navigate("/postproposal");
        } catch (error) {
            console.log(error.message);
        }
    };
    return(
        <>
        <ProposalHeader /> 
        <div className="max-w-1440 mx-auto"> {/* stopper */}
        <div className='flex flex-row justify-center mt-20'>
        <div className='w-[50%]'>
            <b className='flex text-3xl justify-center'>Interested in proposing your reserach?</b>   

            <div className='flex flex-col items-center justify-center mt-5 py-10 mx-auto text-md text-gray-800 border border-gray-400  rounded shadow'>       
                <Link to="/signin">
                    <div className='text-lg font-semibold'>
                    Start with Google Account
                    </div>
                </Link>
                <div onClick={signInWithGoogle} className="mt-5 bg-white text-md font-semibold hover:bg-purple-400 hover:text-white hover:font-semibold hover:border-black text-gray-800 py-2 px-3 border border-gray-400 rounded shadow" type="signinbutton">
                    Sign in with Google 
                </div>
            </div>
        </div>
        </div>
        </div>
        <footer className='text-center'>
                &copy; 2023 Layrd. All Rights Reserved. 
        </footer>   
        </>
    ); 
}

export default ProposalAuthentication; 