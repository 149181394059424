import { Link } from "react-router-dom";


export default function ViewProjectButton() {
    return (
        <>
            <Link 
                to={`/`}
            >
                <button className="w-[240px] h-[67px] bg-theme-purple hover:bg-[#584C7A] rounded-[100px] mt-[2rem] type=myaccount">
                    <p className="text-center text-white text-[22px] font-medium font-roboto">
                        View Projects
                    </p>
                </button>
            </Link>
        </>
    )
}