import React from "react";

function Checkmark({ check }) {
  return (
    <div className="my-[2rem]">
      <div
        className="bg-white rounded-xl shadow-md p-2 hover:bg-gray-100 border"
      >
        <p className="text-center">{check}</p>
      </div>
    </div>
  );
}

export default Checkmark;