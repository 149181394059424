import React, { useState, useEffect } from "react";
import { auth, setPersistence, browserSessionPersistence, onAuthStateChanged } from '../../firebase/firebaseConfig';
import { useAuthState } from 'react-firebase-hooks/auth';
import axios from 'axios';
import { useParams, Link } from "react-router-dom"; 
import { createAccountSas, getResumeContainerClient } from "../../azure/azureConfig";
import RegButton from "./RegButton";


// need to check for the error case, which is DIRECT ACCESS 
// The problem invovles handing ID value -jw

function ApplyResearchResponse({ projects }){
    const [Application, setApplication] = useState([]) // for main
    const [applicationLoading, setApplicationLoading] = useState(true)
    const [user, loading, error] = useAuthState(auth);
    const [fileContent, setFileContent] = useState(null);
    const { id } = useParams() 
    const applications = projects.find((application) => application.id === parseInt(id))


    let uid = user ?.uid; 
    let accessToken = user?.accessToken; 

    //Check if user session is valid 
    //https://jsmobiledev.com/article/firebase-auth-persistence/  (documentation on setPersistence())
    /*
    useEffect(() => {
        setPersistence(auth, browserSessionPersistence)
          .then(() => {
            const unsubscribe = auth.onAuthStateChanged((loggedInUser) => {
              if (loggedInUser) {
                //checks loggedInuser credential 
                const loggedInuser_cred = loggedInUser.accessToken;
                console.log('default check')
                console.log(loggedInuser_cred)
                setUser(loggedInUser);
              }
            });
    
            return () => unsubscribe();
        })
        .catch((error) => {
           console.error('Error setting session persistence:', error);
        });
    }, []);
    */ 

    // Input values 
    const [formValues, setFormValues] = useState({
        questionOne: '',
        questionTwo: '',
        questionThree: '',
        comments:'', 
        resume: '', // -- error found "value` prop on `input` should not be null." -jw
        link: '', 
    }); 

    //Error values 
    const [formErrors, setFormErrors] = useState({
        questionOne: '',
        questionTwo: '',
        questionThree: '', 
        comments: '',
        resume: '',
        link:'', 
    })

    //get word count 
    const countWords = (text) => {
        if (!text) {
            return 0; // Return 0 if the text is undefined or empty
        }
        const trimmedText = text.trim(); // Remove leading and trailing spaces
        const words = trimmedText.split(/\s+/); // Split the text by one or more whitespace characters
        return words.length;
    };

    //check word count
    const isCountAbove80 = () => {
        const wordCount = countWords();
        return wordCount >= 80;
    };

    const handleChange = (event) => {
        const { name, value } = event.target;

        if (name === "resume") {
            const file = event.target.files[0];
            if (file) {
                setFileContent(null);

                const reader = new FileReader();
                reader.onload = (e) => {
                    // The file's text content will be printed here
                    setFileContent(e.target.result);
                };
                reader.readAsArrayBuffer(file);
            }
        }
        setFormValues((prevValues) => ({
          ...prevValues,
          [name]: value,
        }));
    };
    
    /**
     * requests an Azure SAS token
     * @returns SAS token or "error" - commented out for now - since its causing cors error.
     */
    // const getSASToken = async () => {
    //     let postData = {
    //         auth : {
    //             uid, 
    //             id_token: accessToken
    //         }
    //     }

    //     try {
    //         let response = await axios.post(`${process.env.REACT_APP_SERVERURL}/storage/get_token`, postData, {
    //             headers: { 'Content-Type': 'application/json' }
    //         });
    //         if (response.data && response.data.result == "success") {
    //             return response.data.data
    //         } else {
    //             return "error"
    //         }
    //     } catch (error) {
    //         console.log('error submitting app to azure')
    //     }
    // }
    
    /**
     * @param {*} resume_name 
     * @param {*} resume_content 
     * uploads the (resume_content) to the Azure Blob Storage
     * @returns the Azure Blob Storage url of the uploaded content - comment out to bypass azure blob storage
     */
    // const uploadResume = async (resumeName, resumeContent) => {
    //     // Get a block blob client
    //     let sas = await getSASToken()
    //     const resumeContainerClient = getResumeContainerClient(sas);
    //     const blockBlobClient = resumeContainerClient.getBlockBlobClient(resumeName);    
    //     try {
    //         const uploadBlobResponse = await blockBlobClient.uploadData(resumeContent, {
    //             blobHTTPHeaders: {
    //                 blobContentType: "application/pdf",
    //             },
    //         });
    //     } catch (e) {
    //         console.log("upload resume error");
    //         console.log(e);
    //         return "error";
    //     }

    //     return blockBlobClient.url.split("?")[0]
    // }

    // paul: test function - please delete if you'd like to use azure blob storage
    const uploadResume = async (resumeName, resumeContent) => {
        // For testing purposes, bypass the actual upload and return a test URL
        return "https://testResumeLink.com";
    }

    /**
     * @param {*} uid 
     * @param {*} project_id 
     * creates file name of the given resume based on the uid and the project id of the user.
     * @returns file name of the resume
     */
    const createResumeFileName = (uid, project_id) => `${uid}_${project_id}.pdf`

    // handle save
    const handleSave = async (event) => {
        event.preventDefault()
        
        const errors = {};
        let hasError = false;

        // allowing user to save the application without fillout anything
        if (hasError && fileContent != null) {
            setFormErrors(errors);
            console.log('missing answer')
        } else {
            // Handle form submission
            console.log('valid form');
            let skillList = JSON.stringify([formValues.questionOne, formValues.questionTwo, formValues.questionThree])
            let projectId = id
            let resumeFileName = createResumeFileName(uid, projectId)
            let resumeLink = await uploadResume(resumeFileName, fileContent)
            
            // console.log("uid in application: ", uid)
            // console.log("access token in app: ", accessToken)
            
            if (resumeLink == "error") {
                console.log('resume upload error')
            } else {
                let postData = {
                    auth : {
                        uid, 
                        id_token: accessToken
                    }, 
                    data: {
                        project_id: projectId, 
                        owner_uid: uid, 
                        response: skillList || '', 
                        reference_link : formValues.link || '', 
                        comments: formValues.comments || '',
                        reference_link: formValues.link || '',
                        resume_link: resumeLink || '',
                        status: 'saved',
                    }
                }

                try {
                    let response = await axios.post(`${process.env.REACT_APP_SERVERURL}/application/save`, postData, {
                        headers: { 'Content-Type': 'application/json' }
                    });
                    
                    if (response.data && response.data.result === 'success') { // paul - response.data.result shows the outcome of the this request
                        alert("Successfully Applied");
                    } else {
                        console.log("postData:", postData);
                        console.log("response data:", response.data);
                        alert("There was an error saving application");
                    }
        
                } catch (error) {
                    console.log('error saving app')
                }
            }
            setFormErrors({});
        }
    }
      
    //Submit Forms + handle user credential, check empty inputs 
    const handleSubmit = async (event) => {
        // cancels the event if it is cancelable
        event.preventDefault();

        const errors = {};
        let hasError = false;

        //Check for empty inputs and set errors
        Object.entries(formValues).forEach(([key, value]) => {
            if (key === 'resume' && value === null) {
                errors[key] = 'Please upload resume.';
                hasError = true;
            } else if (key !== 'resume' && value.trim() === '') {
                errors[key] = 'Please fill out this field.';
                hasError = true;
            } 
        });

        if (hasError && fileContent != null) {
            setFormErrors(errors);
            console.log('missing answer')
        } else {
            // Handle form submission
            console.log('valid form');
            //console.log(formValues.questionOne, formValues.questionThree)
            //setFormErrors({});

            let skillList = JSON.stringify([formValues.questionOne, formValues.questionTwo, formValues.questionThree])
            let projectId = id
            let resumeFileName = createResumeFileName(uid, projectId)
            let resumeLink = await uploadResume(resumeFileName, fileContent)
            if (resumeLink == "error") {
                // display pop up that resume upload failed
                console.log('resume upload error')
            } else {
                let postData = {
                    auth : {
                        uid, 
                        id_token: accessToken
                    }, 
                    data: {
                        project_id: projectId, // bit ambiguous -- is there unique id? 
                        owner_uid: uid, 
                        response: skillList, 
                        reference_link : formValues.link, 
                        comments: formValues.comments,
                        reference_link: formValues.link,
                        resume_link : resumeLink
                    }
                }

                try {
                    let response = await axios.post(`${process.env.REACT_APP_SERVERURL}/application/submit`, postData, {
                        headers: { 'Content-Type': 'application/json' }
                    });
                    if (response.data && response.data.success) {
                        alert("Successfully Applied")
                    } else {
                        alert("There was an error submitting application")
                    }
        
                    //console.log(response.data);
                } catch (error) {
                    console.log('error submitting app')
                    //console.log('There an error:', error.response.status);
                    //console.log('More about error', error.response.data)
                }
            }
            setFormErrors({});
        }
    };

    return(
        <>
        <form id="applyResearchForm" className='flex flex-col mb-10 mx-auto' onSubmit={handleSubmit}>
            <div className="flex items-baseline justify-between text-3xl mt-5 py-5 mb-4 text-black">
                <div className="flex flex-col">
                    <div className="my-auto font-semibold ">Your Response</div>
                    {user ? 
                    (<div></div>) :
                    <div className="text-xl">
                        Please Sign in to start 
                    </div>}
                </div>
                {user ? (
                    < RegButton type="button" text="Save" onClick={handleSave} />) :
                    <div></div>
                }
            </div>

        
            <div className="w-full mb-6">
                <label className="block uppercase tracking-wide text-gray-700 text-lg font-semi mb-2" htmlFor="grid-questionOne">
                <b>Q1:</b><p>{JSON.parse(applications.questions)[0]}</p>
                </label>
                
                <b className="mb-1">Your Answer: </b>
                <textarea
                    style={{ resize: 'none', overflowY: "auto"}} 
                    className={`apearance-none block w-full bg-gray-200 text-gray-700 border  ${formErrors.questionOne ? 'border-red-500' : 'border-gray-200'}  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
                    id="grid-questionOne"
                    placeholder="Describe Required Responsibiliy for the research"
                    rows={7}
                    name="questionOne"
                    value={formValues.questionOne}
                    onChange={handleChange}
                    disabled={!user}
                ></textarea>
                {formErrors.questionOne && (<p className="text-red-500 text-xs italic">{formErrors.questionOne}</p>)}
                    {!isCountAbove80() && (
                            <p className="text-black-500 text-xs italic">
                            {(() => {
                                let wordsLeft = 80 - countWords(formValues.questionOne);
                    
                                return wordsLeft > 0 ? `${wordsLeft} more words to go!` :
                                wordsLeft === 0 ? 'yay!' :
                                'wow!'
                            })()}
                        </p>
                    )}         
            </div>

            <div className="w-full mb-6">
                <label className="block uppercase tracking-wide text-gray-700 text-lg font-semi mb-2" htmlFor="grid-questionTwo">
                <b>Q2:</b>
                <p>{JSON.parse(applications.questions)[1]}</p>
                </label>

                <b className="mb-1">Your Answer: </b>
                <textarea
                    style={{ resize: 'none', overflow: 'auto' }} 
                    className={`apearance-none block w-full bg-gray-200 text-gray-700 border  ${formErrors.questionTwo ? 'border-red-500' : 'border-gray-200'}  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
                    id="grid-questionTwo"
                    placeholder="Describe Required Qaulifications"
                    rows={7}
                    name="questionTwo"
                    value={formValues.questionTwo}
                    onChange={handleChange}
                    disabled={!user}
                ></textarea>
                {formErrors.questionTwo && (<p className="text-red-500 text-xs italic">{formErrors.questionTwo}</p>)}
                    {!isCountAbove80() && (
                        <p className="text-black-500 text-xs italic">
                            {(() => {
                                let wordsLeft = 80 - countWords(formValues.questionTwo);
                
                                return wordsLeft > 0 ? `${wordsLeft} more words to go!` :
                                wordsLeft === 0 ? 'yay!' :
                                'wow!'
                            })()}
                        </p>
                    )}         
            </div>

            <div className="w-full mb-6">
                <label className="block uppercase tracking-wide text-gray-700 text-lg font-semi mb-2" htmlFor="grid-questionThree">
                <b>Q3: submitted question 3</b>
                <p>{JSON.parse(applications.questions)[2]}</p>
                </label>

                <b className="mb-1">Your Answer: </b>
                <textarea
                    style={{ resize: 'none', overflow: 'auto' }} 
                    className={`apearance-none block w-full bg-gray-200 text-gray-700 border  ${formErrors.questionThree ? 'border-red-500' : 'border-gray-200'}  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
                    id="grid-questionThree"
                    placeholder="Describe Additional or Preferred Qualifications"
                    rows={7}
                    name="questionThree"
                    value={formValues.questionThree}
                    onChange={handleChange}
                    disabled={!user}
                ></textarea>
                {formErrors.questionThree && (<p className="text-red-500 text-xs italic">{formErrors.questionThree}</p>)}
                    {!isCountAbove80() && (
                        <p className="text-black-500 text-xs italic">
                            {(() => {
                                let wordsLeft = 80 - countWords(formValues.questionThree);
                
                                return wordsLeft > 0 ? `${wordsLeft} more words to go!` :
                                wordsLeft === 0 ? 'yay!' :
                                'wow!'
                            })()}                    
                        </p>
                    )}         
            </div>
        
            {/* convert into bullet point */}
            <div className="w-full mb-6">
                <label className="block uppercase tracking-wide text-gray-700  text-lg font-semi mb-2" htmlFor="grid-comments">
                    <b>Comments (Research style, Vision, Introduction etc)</b>
                </label>
                <textarea
                    style={{ resize: 'none', overflow: 'auto' }} 
                    className={`apearance-none block w-full bg-gray-200 text-gray-700 border  ${formErrors.comments ? 'border-red-500' : 'border-gray-200'}  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
                    id="grid-comments"
                    placeholder="Describe Additional Notes, regarding research style or include introduction and vision"
                    rows={7}
                    name="comments"
                    value={formValues.comments}
                    onChange={handleChange}
                    disabled={!user}
                ></textarea>
                {formErrors.comments && (<p className="text-red-500 text-xs italic">{formErrors.comments}</p>)}
                    {!isCountAbove80() && (
                        <p className="text-black-500 text-xs italic">
                        {(() => {
                            let wordsLeft = 80 - countWords(formValues.comments);
            
                            return wordsLeft > 0 ? `${wordsLeft} more words to go!` :
                            wordsLeft === 0 ? 'yay!' :
                            'wow!'
                        })()}                    
                        </p>
                    )}         
            </div> 

            <div className="w-full mb-6">
                <label className="block uppercase tracking-wide text-gray-700 text-lg font-semi mb-2" htmlFor="grid-referencelink">
                    <b>Reference Link</b>
                </label>
                <textarea
                    style={{ resize: 'none', overflow: 'auto' }} 
                    className={`apearance-none block w-full bg-gray-200 text-gray-700 border  ${formErrors.link ? 'border-red-500' : 'border-gray-200'}  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
                    id="grid-link"
                    placeholder="LinkedIn, Personal Website, Google Scholars etc ..."
                    rows={1}
                    name="link"
                    value={formValues.link}
                    onChange={handleChange}
                    disabled={!user}
                ></textarea>
                {formErrors.link && (<p className="text-red-500 text-xs italic">{formErrors.link}</p>)}        
            </div> 

            <div className="w-full mb-6">
                <label className="block uppercase tracking-wide  text-gray-700 text-lg font-semi mb-2" htmlFor="resume">
                    <b>Resume Upload</b>
                </label>
                <input
                    type="file"
                    id="resume"
                    name="resume"
                    accept=".pdf"
                    value={formValues.resume}
                    onChange={handleChange}
                    disabled={!user}
                    className={`apearance-none block w-full bg-gray-200 text-gray-700 border ${formErrors.resume ? 'border-red-500' : 'border-gray-200'}  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
                />
                {formErrors.resume && (<p className="text-red-500 text-xs italic">Please fill out this field.</p>)}                  
            </div> 
        </form>
        </>
    );
}

export default ApplyResearchResponse; 