function DemoPost() {
    const demo_posts = [
        {
            postId: "1", 
            writer: "Paul Nam",
            title: "The starting",
            annoucement_description: "First Annoucement Post in BlockRearch Team",
            content: 
            `We are thrilled to announce the launch of our cutting-edge blockchain startup! At [Company Name], we are driven by a shared passion for innovation and the transformative potential of blockchain technology. While we can't disclose all the exciting details just yet, we wanted to take a moment to introduce ourselves and provide a glimpse into our vision.

            🌐 About Us:
            At [Company Name], our mission is to revolutionize industries through the power of blockchain. With a team of seasoned experts and industry professionals, we are dedicated to harnessing the potential of this groundbreaking technology to create solutions that drive efficiency, transparency, and trust.
            
            As an emerging player in the blockchain space, we are committed to pushing the boundaries of what's possible. Our team is comprised of visionaries, engineers, and strategists who share a common goal: to empower businesses and individuals by leveraging the immense power of distributed ledger technology.
            
            While we may be keeping the specifics under wraps for now, we assure you that we are diligently working behind the scenes to develop innovative solutions that will reshape the landscape. Stay tuned for future updates as we unveil our groundbreaking initiatives and embark on this exciting journey together.
            
            We appreciate your enthusiasm and support as we embark on this exciting chapter. Keep an eye out for further updates, as we'll be sharing more information in the near future. Together, let's pave the way for a blockchain-powered future!
            
            Best regards,
            Paul Nam
            BlockRsearch`,
            view: 10,
            number_comments: 3,
            written_date: "06/12/2023",
            share_button: "Share", // need to create a share button component. Just a demo right now.
        },
        {
            postId: "2",
            writer: "Paul Nam",
            title: "What's Next?",
            annoucement_description: "Get Ready for an Epic Adventure!",
            content: 
            `
            We are beyond grateful for the incredible patience and anticipation you've shown as we put the finishing touches on our website. You've been eagerly waiting, and we're thrilled to announce that the moment is almost here! We want to take a moment to express our sincere appreciation for your support and assure you that the wait will soon be worth it.

            To all the adventure seekers, pioneers, and curious minds out there, we're about to embark on an exhilarating journey together. We're not just building a website; we're creating an immersive experience that will leave you captivated and inspired. And who better to shape this adventure than you, our amazing users?

            At [Company Name], we firmly believe in the power of collaboration. We're not just here to present you with a static platform; we're here to co-create, innovate, and have a blast along the way! Your feedback, ideas, and insights are the fuel that propels us forward. We want to hear your thoughts, your dreams, and your wildest ideas. Together, we'll build something truly remarkable.

            Imagine a world where your ideas become reality, where your voice matters, and where your journey is as thrilling as the destination. That's the world we're striving to create, and we can't wait for you to join us on this wild ride. So buckle up, hold on tight, and get ready to explore uncharted territories of fun, excitement, and endless possibilities.

            Stay tuned as we prepare to unveil our vibrant and interactive platform. We'll keep you in the loop every step of the way, teasing you with sneak peeks, behind-the-scenes stories, and exclusive previews. The countdown has begun, and we're eagerly awaiting the moment when we can finally share our creation with you.

            In the meantime, feel free to share your thoughts, hopes, and dreams. Connect with us on social media, drop us a line, and let's start this incredible journey together. Adventure awaits, and we're honored to have you as a crucial part of it!

            So get ready, dear adventurer, because something extraordinary is about to unfold. Get your imagination revved up, bring your excitement to the max, and prepare to be blown away. Together, we'll create a world where dreams come true, and the fun never ends.

            Stay tuned, stay awesome, and get ready to unleash the adventure of a lifetime!

            Warm regards,
            The [Company Name] Team
            `,
            view: 16,
            number_comments: 4,
            written_date: "06/16/2023",
            share_button: "Share", // need to create a share button component. Just a demo right now.
        },
        {
            postId: "3",
            writer: "Paul Nam",
            title: "Title-3",
            annoucement_description: "Example2 sentence",
            content: "This is the third example content.",
            view: 16,
            number_comments: 4,
            written_date: "06/16/2023",
            share_button: "Share", // need to create a share button component. Just a demo right now.
        },
    ]
    return demo_posts
}

export default DemoPost;