import React from 'react'
import { useState, useEffect } from 'react';
import InvolveEventCheckbox from './involveEventCheckbox';
import RemoteCheckbox from './remoteCheckbox';

function Category({ involvesEvent, isRemoteOnly, setInvolvesEvent, setIsRemoteOnly }) {


    return (
        <div className='group'>
            <div className="flex items-center">
                <button 
                    id="dropdownDefaultButton" 
                    data-dropdown-toggle="dropdown" 
                    className="justify-center group-hover:text-black rounded-lg border border-zinc-500 bg-white hover:bg-black-800 focus:outline-none font-medium rounded-lg text-sm w-[13rem] sm:w-[6.75rem] h-8 text-center inline-flex items-center dark:bg-white hover:bg-purple-400" 
                    type="button">
                        Category
                    <svg className="w-2.5 h-2.5 ml-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4"></path>
                    </svg>
                </button>   
            </div>
            <div id="dropdown" className="z-10 hidden group-hover:block absolute divide-y divide-gray-100 rounded-lg shadow w-[13rem] sm:w-[6.75rem] bg-white">
                <ul className="py-2 text-sm dark:text-black" aria-labelledby="dropdownDefaultButton">
                    <li><div className="flex flex-row px-4 py-2 hover:bg-gray-100 dark:hover:bg-purple-400 dark:hover:text-white"><InvolveEventCheckbox isChecked={involvesEvent} toggleChecked={() => setInvolvesEvent(!involvesEvent)} /><p className="text-[1rem] ml-2"><b>Event</b></p></div></li>
                    <li><div className="flex flex-row px-4 py-2 hover:bg-gray-100 dark:hover:bg-purple-400 dark:hover:text-white"><RemoteCheckbox isChecked={isRemoteOnly} toggleChecked={() => setIsRemoteOnly(!isRemoteOnly)} /><p className="text-[1rem] ml-2"><b>Remote</b></p></div></li>
                </ul>
            </div>
        </div>
    )
}

export default Category