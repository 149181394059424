import DemoPost from "./post_data";
import AnnoucementListBar from "./ui/announcementListBar";
import { Link } from "react-router-dom";


function AnouncementList() {
    return (
        <div className="announcement-container flex justify-center items-center">
            <div className="annoucement-list-container flex flex-col w-[80rem] justify-center items-center border-solid">
                < AnnoucementListBar />
                {
                    DemoPost().map(
                        ({
                            postId,
                            writer,
                            title,
                            annoucement_description,
                            view,
                            written_date,
                            share_button,
                        }) => {
                            const link = `/announcement/${postId}`; // Create a unique link using the postId

                            return (
                                <Link to={link} key={postId}>
                                    <div className="annoucement-list flex w-[78rem] my-2 border-solid hover:border-double border-4 border-x-fuchsia-400 h-12 items-center">
                                        <div className="announcement-writer flex justify-center w-32 border-r-2">
                                            <h3 className="announcement-writer-list hover:font-bold">{written_date}</h3>
                                        </div>
                                        <div className="announcement-title flex justify-center w-[15rem] border-r-2">
                                            <h1 className="announcement-title-list hover:font-bold">{title}</h1>
                                        </div>
                                        <div className="announcement-description flex justify-start w-[32rem] border-r-2">
                                            <p className="announcement-description-list hover:font-bold ml-[1rem]">{annoucement_description}</p>
                                        </div>
                                        <div className="announcement-view flex justify-center w-32 border-r-2">
                                            <p className="announcement-view-list hover:font-bold">{writer}</p>
                                        </div>
                                        <div className="announcement-comments flex justify-center w-[6rem] border-r-2">
                                            <p className="announcement-comments-list hover:font-bold">{view}</p>
                                        </div>
                                        <div className="announcement-share w-[8rem] flex justify-center">
                                            <p className="announcement-share-list hover:font-bold">{share_button}</p>
                                        </div>
                                    </div>
                                </Link>
                            )
                        }
                    )
                }
            </div>
        </div>
    )
}

export default AnouncementList;