import React from "react";
import shareIcon from "../../../img/share.png"

export default function ShareButton() {
    return (
      <>
        <div className="flex items-center mt-1">
              <img src={shareIcon} alt="shareicon" width="20" height="20" fill="currentColor" className="ml-[1em] hover:text-purple-700 active:mb-[0.5rem]" /> 
        </div>  
      </>
    );
}