import Header from "../main/header"; // header tab (same as the one from landing page)
import SearchComponent from "./ui/searchBar";
import AnnoucementList from "./announcement_list";

function Announcement() {
    return(
        <div className="max-x-xl w-full">
            <div className="flex flex-col h-screen">
                <div>
                    <Header />
                </div>
                <div className="mt-20">
                    <div className="ml-[15rem]">
                        <SearchComponent />
                    </div>
                    <AnnoucementList />
                </div>
            </div>
            <footer className='text-center'>
                &copy; 2023 Laryd. All Rights Reserved. 
            </footer>      
        </div>
    );
}

export default Announcement; 