import logo from '../../img/layrd-logo.png';
import { Link, useNavigate } from "react-router-dom"; 
import { auth, provider, signInWithPopup } from '../../firebase/firebaseConfig';

function ApplyAuthentication(){
    const navigate = useNavigate();
    
    const signInWithGoogle = async () => {
        try {
            let user_cred = await signInWithPopup(auth, provider);
            let id_token = await user_cred.user.getIdToken();
            navigate("/apply"); // need to redirect to the exact proposal page 
        } catch (error) {
            console.log(error.message);
        }
    };

    return(
        <>
            <header className='flex justify-between bg-white text-xl border-b border-black py-2'>
            <nav className="flex justify-between w-full mx-5">
                <div>
                    <ul className="flex items-center">
                        <li>
                            <Link to="/">
                            <img src={logo} alt="logo" className='h-12'/> 
                            </Link>
                        </li>
                        {/*
                        <li className='ml-2 p-2'>
                            <Link to="/stat">
                                Stat
                            </Link>                    
                        </li>

                        <li className='p-2'>
                            <Link to="/announcement">
                                Announcement
                            </Link>
                        </li>
                        */}
                        <li className='bg-white text-lg hover:bg-purple-400 hover:text-white hover:border-black text-gray-800 py-2 px-3 border font-semibold border-gray-400 rounded shadow ml-3 my-auto type=postproposal'>
                            <a href='https://blockresearch.gitbook.io/blockresearch/'  target="_blank" rel="noreferrer">Docs</a>
                        </li>
                    </ul>
                </div>

                <div>
                <ul>   
                    <li>
                        <button className='bg-white text-lg hover:bg-purple-400 hover:text-white hover:border-black text-gray-800 py-2 px-3 border font-semibold border-gray-400 rounded shadow'> 
                        <Link to="/auth/proposal">
                            Post Proposal
                        </Link>
                        </button>
                    </li>
                </ul>
                </div>
            </nav>
            </header>
            <div className="max-w-1440 mx-auto"> {/* stopper */}
            <div className='flex flex-row justify-center mt-20'>
                <div className='w-[50%]'>
                    <b className='flex text-3xl justify-center'>Welcome to Layrd - Apply</b>   

                    <div className='flex flex-col items-center justify-center mt-5 py-10 mx-auto text-md text-gray-800 border border-gray-400  rounded shadow'>       
                        <Link to="/auth/apply">
                            <div className='text-lg font-semibold'>
                                Already have an account
                            </div>
                        </Link>
                        <div onClick={signInWithGoogle} className="mt-5 bg-white text-md font-semibold hover:bg-purple-400 hover:text-white hover:font-semibold hover:border-black text-gray-800 py-2 px-3 border border-gray-400 rounded shadow" type="signinbutton">
                            Sign in with Google 
                        </div>
                    </div>
                </div>
                </div>
            </div>

            <footer className='text-center'>
                &copy; 2023 Laryd. All Rights Reserved. 
            </footer> 
        </>
    ); 
}

export default ApplyAuthentication; 