import React, { useState, useRef, useEffect } from 'react';
import { auth } from '../../firebase/firebaseConfig';
import { useAuthState, useIdToken } from 'react-firebase-hooks/auth';
import ProposalAuthentication from './authentication'
import Header from "../main/header"
import InputFormOne from './step-one-about';
import InputFormTwo from './step-two-looking';
import InputFormThree from './step-three-outcome';

const PostProposal = () => {
    const [user, loading, error] = useIdToken(auth);
    
    const [formValues, setFormValues] = useState({
        //what is your reserach about
        name: '',
        organization: '',
        // NEW DATA POINT: correlated event 
        withEvent: '', 
        eventName: '',
        eventLink: '', 
        // -- orignal
        title: '',
        recruitmentType: '',
        topic1: '',
        topic2: '',
        topic3: '',
        startDate: '',
        endDate: '',
        workTimePerDay: '',
        city: '',
        state: '',
        workLocation: '',
        participantsNum: '',
        appType: '',
        appCloseDate: '',
        researchDescription: '',
        //who are you looking for 
        skill1: '',
        skill2: '',
        skill3: '',
        requiredQualification: '',
        additionalNotes: '',
        questionOne: '', 
        questionTwo: '', 
        questionThree: '',
        resultPostDate: '', 
        // FINAL DELIVERY related
        deliveryTypeOne: '',
        deliveryTypeTwo: '',
        deliveryTypeThree: '', 
        deliveryDescription: '',
    });

    const onSaveClick = async () => {
        console.log('save clicked')
        console.log('form values', formValues);
        console.log('user uid', user.uid) // can be customized 
    }

    const [currentStep, setCurrentStep] = useState(1);
    const [lineWidth, setLineWidth] = useState(0);
    const [lineOffset, setLineOffset] = useState(0);
    const stepRefs = useRef([]);

    useEffect(() => {
        if (stepRefs.current[currentStep-1]) {
            setLineWidth(stepRefs.current[currentStep-1].offsetWidth);
            setLineOffset(stepRefs.current[currentStep-1].offsetLeft);
        }
    }, [currentStep]);


    if (!user) {
        return (
          <ProposalAuthentication />
        );
    }
    
    // directly set the current step to the clicked step
    const goToStep = (step) => setCurrentStep(step);

    // function to handle signout
    const handleSignout = async () => {
        try {
            await auth.signOut();
        } catch (err) {
            console.error("Signout Error", err);
        }
    };

    if (error) {
        return <div>Error: {error.message}</div>;
    }

        
    const steps = [
        { number: 1, label: 'Step 1. What is your research about?' },
        { number: 2, label: 'Step 2. Who are you looking for?' },
        { number: 3, label: 'Step 3. What is the final outcome?' }
    ];

    const renderStepNavigation = () => {
        return (
            <>
                <div className="flex flex-col w-[70%] relative">
                    <div className="flex justify-start space-x-4">
                        {steps.map((step, index) => (
                            <button
                                key={step.number}
                                ref={el => stepRefs.current[index] = el}
                                onClick={() => goToStep(step.number)}
                                className={`text-[16px] font-medium font-roboto ${currentStep === step.number ? 'text-black' : 'text-[#A3A2A4]'}`}
                            >
                                {step.label}
                            </button>
                        ))}
                    </div>
                    <div className="relative">
                        <hr className="absolute bg-[#6750A4] h-[3px]" style={{ width: `${lineWidth}px`, marginLeft: `${lineOffset}px` }} />
                        <hr className="bg-[#DFDFEF] h-[3px] w-full" />
                    </div>
                </div>
            </>
        );
    };

    const renderStep = () => {
        switch(currentStep) {
            case 1:
                return (
                    <>
                        <div className='flex justify-center sm:w-[100%] w-[0%] mt-[2rem]'>
                            <div className="flex justify-center items-center sm:w-[70%]">
                                <InputFormOne formValues={formValues} setFormValues={setFormValues}/>
                            </div>
                        </div>
                    </>
                );
            case 2:
                return (
                    <>
                        <div className='flex justify-center sm:w-[100%] w-[0%] mt-[2rem]'>
                            <div className="flex justify-center items-center sm:w-[70%]">
                                <InputFormTwo formValues={formValues} setFormValues={setFormValues}/>
                            </div>
                        </div>
                    </>
                );
            case 3: 
                return (
                    <>
                        <div className='flex justify-center sm:w-[100%] w-[0%] mt-[2rem]'>
                        <div className="flex justify-center items-center sm:w-[70%]">
                                <InputFormThree formValues={formValues} setFormValues={setFormValues}/>
                            </div>
                        </div>
                    </>
                );
        }
    };

    if (user) {
    return (
        <div>
            <div className="pt-[3rem]">
                <Header />
            </div>
            <div className="flex justify-center w-[100%] mt-[4.5rem]">
                <div className="flex justify-start w-[70%] font-bold text-[22px] font-roboto">
                    Post Proposal
                </div>
            </div>
            <div className="flex justify-center mt-[3rem]">
                {renderStepNavigation()}
            </div>
            <div>
                {renderStep()}
            </div>
            <footer className='text-center'>
                &copy; 2023 Layrd. All Rights Reserved. 
            </footer>   
        </div>
    );
    }
}

export default PostProposal; 


