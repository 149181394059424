import { Link, useParams } from  "react-router-dom"; 


function ApplyResearchDescription({ projects }){
    const { id } = useParams()
    const applications = projects.find((application) => application.id === parseInt(id))
    
    // applications.apptype convert 
    let decisionType = ''
    if (applications.app_type === 'due_date'){
        decisionType = 'Accepting until Due Date'
    } else if (applications.app_type === 'rolling') {
        decisionType = 'Rolling Admission'
    } else {
        decisionType = 'Error'
    }

    // applications.work_location convert 
    let location = ''
    if (applications.work_location === 'on_site'){
        location = 'On Site Required'
    } else if (applications.work_location === 'remote') {
        location = 'Remote Only'
    } else if(applications.work_location === 'hybrid'){
        location = 'Hybrid (adjust with the sponsor)'
    } else {
        location = "Error"
    }



    const timestampInSeconds = Math.floor(Date.now() / 1000);
    const postedDays = function calc_posted_days() {
        const differenceInMilliseconds = timestampInSeconds - applications.created_at
        const differenceInDays = Math.round(differenceInMilliseconds / 86400);
        
        return differenceInDays;
    }



    return(
        <div className='flex flex-col justify-center'>   
            
            <div className="text-2xl mx-3 p-1 pt-2 my-3 text-black font-semibold">
                Basic Information
            </div>                
            
            <div className="mb-3 mx-3 px-1 text-xl justify-end">
                <div><b>Title:</b> {applications.title}</div> 
                <div><b>Proposer:</b> {applications.name}, {applications.organization}</div> 
                <div><b>Type: </b>Looking for {convertPartnerType(applications.recruitment_type)}</div>
                <div><b>Location:</b> {applications.city}, {applications.state} </div>    
                <div className="mt-2"><b>[{location}]</b></div>    
            </div>
            
            <div className="text-2xl mx-3 p-1 mb-3 mt-[3.5rem] text-black font-semibold">
                Commitment
            </div>
            
            <div className="mx-3 px-1 text-xl justify-end">
                <div><b>Start Date: </b> {convertMMDDYY(applications.start_date)}</div>
                <div><b>End Date: </b>{convertMMDDYY(applications.end_date)}</div>
                <div><b>Commit hours per week:</b> {applications.work_time_per_day} hours</div>
                {/* <div><b>Difficulty Level:</b>5</div></div>
                -- not relevant information at this point -jw*/}
            </div>
            
            <div>
                <div>   
                    <div className="mt-5 text-2xl mx-3 p-1 mt-[3.5rem] text-black font-semibold">
                        Summary
                    </div>

                    <div className="mx-3 p-1 mt-2">                 
                        <p>{JSON.parse(applications.topics).map((skill, index) => (
                            <li key={index}>{skill}</li>
                        ))}</p>

                        <p className="text-lg my-3">
                        {applications.description}
                        </p>
                    </div>
                </div>

                <div className="mt-5 text-2xl mx-3 p-1 mb-3 mt-[3.5rem] text-black font-semibold">
                    Knowledge, Skills & Abilities
                </div>

                <div className="mx-3 p-1 mt-2">                 
                    <p>{JSON.parse(applications.skills).map((skill, index) => (
                        <li key={index}>{skill}</li>
                    ))}</p>

                    <p className="text-lg my-3">
                    {applications.required_qualifications}
                    </p>
                </div>
            </div>

            <div className="mt-5 text-2xl mx-3 p-1 mb-3 mt-[3.5rem] text-black font-semibold">
                Research Outcome / Final Delivery
            </div>

            <div className="mx-3 px-1 text-lg">
                <p>{JSON.parse(applications.final_delivery_types).map((skill, index) => (
                    <li key={index}>{skill}</li>
                ))}</p>

                <p className="text-lg my-3">
                {applications.final_delivery_description}
                </p>
            </div>
        </div>

    );
}

export default ApplyResearchDescription; 



// converting unix time stamp to actual time
const convertMMDDYY = function unix_timeConverter(unix_timestamp) {
    var a = new Date(unix_timestamp * 1000)
    var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    //var hour = a.getHours();
    //var min = a.getMinutes();
    //var sec = a.getSeconds();
    {/* only we need is Month / Date / Year */}                              
    var time = month + ' '+ date + ', ' + year;
    return time;
}

// converting Looking for variable 

function convertPartnerType(project_type){
    let convertedType = ''
    if (project_type === "find_mentor") {
        convertedType = "Mentor"
    } else if (project_type === "find_mentee" ) {
        convertedType = "Mentee"
    } else if (project_type === "find_team_member" ) {
        convertedType = "Team Member"
    }
    return convertedType
}
