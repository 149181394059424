import React from "react";
import { useParams, Link } from "react-router-dom";
import Header from "./header";

function ProposalDetails({ sortedProjects }) {
    const { id } = useParams()

    const proj_list = sortedProjects?.map((project) => project) // mapping through each data
    const project = proj_list.find((project) => project.id === parseInt(id)) // chose a specific data by id

    // loading is happening here
    if (!project) {
        return <div>loading ... </div>; 
    }

    const convertMMDDYY = function unix_timeConverter(unix_timestamp) {
        var a = new Date(unix_timestamp * 1000)
        var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
        var year = a.getFullYear();
        var month = months[a.getMonth()];
        var date = a.getDate();                             
        var time = month + ' '+ date + ', ' + year;
        return time;
    }
    
    const projectDays = function calc_project_days() {
        // Assuming first_project.start_date and first_project.end_date are Date objects
        const differenceInMilliseconds = project.end_date - project.start_date
        const differenceInDays = differenceInMilliseconds / 86400;
        return differenceInDays;
    }

    return (
        <div className="layout max-x-xl w-full">
            <Header />
            <Link 
                to={`/apply/${project.id}`}
            >
                <button className="ml-[3rem] mt-[5rem] bg-white text-lg hover:bg-purple-400 hover:text-white hover:border-black text-gray-800  h-full ml-5 max-h-12 py-2 px-[2.5rem] border font-semibold border-gray-400 rounded shadow type=myaccount">
                    Apply
                </button>
            </Link>
            <div className="flex flex-col items-center h-screen px-[3rem]">
                <div className="flex flex-col justify-center w-full mt-[2rem]">
                    <div className="post-header my-5">
                        <p className="pb-[0.5rem]">Posted {projectDays()} Days Ago</p>
                        <h1 className="text-5xl roboto font-bold">{project.title}</h1>
                    </div>
                    <p className="text-2xl">Summary:</p>
                        <p className='my-3'>{JSON.parse(project.topics).map((skill, index) => (
                                <li key={index}>{skill}</li>
                            ))}
                        </p>
                        
                        <p className="pb-[1rem]">
                            {project.description}
                        </p>
                    <p className="text-2xl">Knowledge, Skills & Abilities:</p>
                    <p className="texts pb-[0.5rem]"> 
                        {JSON.parse(project.skills).map((skill, index) => (
                                <li key={index}>{skill}</li>
                        ))}                    
                    </p>
                    <p className="pb-[1rem]">
                        {project.required_qualifications}
                    </p>
                    <p className="text-2xl">Preferred Qualifications:</p>
                    <p className="pb-[1rem]">
                        {project.notes}
                    </p>
                    <p className="text-2xl">Research Outcome / Final Delivery:</p>
                    <p className="text pb-[0.5rem]"> 
                        {JSON.parse(project.final_delivery_types).map((skill, index) => (
                            <li key={index}>{skill}</li>
                        ))}                   
                    </p>
                    <p>
                        {project.final_delivery_description}
                    </p> 

                </div>

            <Link to={`/apply/${project.id}`}>
                <button className="mt-[5rem] bg-white text-lg hover:bg-purple-400 hover:text-white hover:border-black text-gray-800  h-full ml-5 max-h-12 py-2 px-[2.5rem] border font-semibold border-gray-400 rounded shadow type=myaccount">
                    Apply
                </button>
            </Link>
            <footer className="footer">
                <div className='footer-text text-center'>
                    &copy; 2023 Layrd. All Rights Reserved. 
                </div>
            </footer>
            </div>
        </div>
    );
}

export default ProposalDetails;
