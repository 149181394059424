import '../../styles/main.css';
import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useParams } from 'react-router-dom';
import MobileProposal from './mobileViewProposal';
import Header from './header';
import SubscribeBar from './ui/subscribeBar';
import SearchComponent from './ui/searchBar';
import DesktopProposal from './desktopViewProposal';
import DesktopProposalDetails from './desktopProposalDetails';
import DesktopProposalDetailsDefault from './defaultDesktopProposalDetails';
import SortIcon from './ui/sortIcon';
import Category from './ui/category';

function Main({ sortedProjects, setPage, page, pageSize, total }) {
    const [isMobile, setMobile] = useState(false) 
    const [isDesktop, setDesktop] = useState(false)
    const [isLoading, setLoading] = useState(true)
    const [showShareButton, setShowShareButton] = useState(null) 
    const [displayedProjects, setDisplayedProjects] = useState([])

    // Search Related
    const [searchTerm, setSearchTerm] = useState("")
    const [isRemoteOnly, setIsRemoteOnly] = useState(false)
    const [involvesEvent, setInvolvesEvent] = useState(false)

    // These codes are required to pick specific proposal details for proposal
    const { id } = useParams()
    const selectedProject = sortedProjects?.find(project => project.id === parseInt(id))

    // loading button useEffect
    useEffect(() => {
        const startIndex = (page - 1) * pageSize
        const endIndex = startIndex + pageSize
    
        const filteredProjects = sortedProjects?.filter(project => {
            let matchesSearch = (
                project.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
                project.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                project.recruitment_type.toLowerCase().includes(searchTerm.toLowerCase()) ||
                project.city.toLowerCase().includes(searchTerm.toLowerCase()) ||
                project.state.toLowerCase().includes(searchTerm.toLowerCase())
            )

            let matchesRemote = !isRemoteOnly || project.work_location === 'remote';
            let matchesEvent = !involvesEvent || project.with_event === 1;

            return matchesSearch & matchesRemote & matchesEvent
        })
        
        const sortedAndFilteredProjects = filteredProjects?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)); 
        const displayedProjects = sortedAndFilteredProjects?.slice(0, page * pageSize);
        setDisplayedProjects(displayedProjects)

    }, [page, sortedProjects, pageSize, searchTerm, isRemoteOnly, involvesEvent]);

    const updateMedia = () => {
        setMobile(window.innerWidth < 640)
        setDesktop(window.innerWidth >= 640)
      };
    
    useLayoutEffect(() => {
        updateMedia();
        setLoading(false)
    }, []);

    return(
        <>
        <div className="pb-[4rem] pt-[3rem]">
            <Header sortedProjects={sortedProjects} />
        </div>
        <div>
            {/* <div className='py-12 px-11 text-4xl text-white bg-black'>
                Find Your Innovation Partner 
            </div> */}

            <div>
                <div className="max-w-1440 mx-auto"> {/* stopper */}
                    <div className='flex flex-col sm:flex-col py-3 w-[100%] text-2xl items-center space-y-[2rem]'>

                        {/* search bar, category, and sort buttons */}
                        <div className="flex flex-col sm:flex-row w-[100%] sm:space-x-[2rem]">
                            <div className="flex w-[100%] items-center sm:justify-end">
                                <div className="flex w-[504px] justify-center">
                                    <SearchComponent setSearchTerm={setSearchTerm} />  
                                </div>
                            </div>
                            <div className="flex flex-row w-[100%] w-full sm:space-x-[1rem] justify-center sm:justify-start items-center">
                                {/* drop down menu 2 */}
                                <div>
                                    <Category isRemoteOnly={isRemoteOnly} involvesEvent={involvesEvent} setIsRemoteOnly={setIsRemoteOnly} setInvolvesEvent={setInvolvesEvent} />
                                </div>
                                <div>
                                    <SortIcon />
                                </div>
                            </div>
                        </div>

                        {isDesktop &&
                        <div className="flex flex-col sm:flex-row w-[100%] space-x-[0rem] sm:space-x-[2rem]">

                            {/* project proposal view - left cards */}
                            <div className="flex flex-col w-[100%] justify-between">
                                <div className="flex w-[100%] items-center justify-end">
                                    <div className="w-[504px]">
                                        {/* get the params id from DesktopProposal and pass that specific id to DesktopProposalDetails */}
                                        {isLoading ? <div></div> : (displayedProjects?.map((project) => <DesktopProposal key={project.id} project={project} showShareButton={showShareButton} setShowShareButton={setShowShareButton} />))}
                                    </div>                       
                                </div>
                                <div className='flex w-[100%] justify-end text-center'>
                                    <div className='flex w-[504px] justify-center text-center'>
                                    {/* show load button when only sortedProjects.length is lower than total amount of project is loaded */}
                                    {
                                        page * pageSize < total 
                                        ? 
                                        <button 
                                            className='bg-white text-lg hover:bg-purple-400  hover:text-white hover:border-black text-gray-800 py-2 px-20 border font-semibold border-gray-400 rounded shadow'
                                            type='button'
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                setPage(page + 1);
                                            }}
                                        >
                                            Load more 
                                        </button>
                                        : 
                                        <div className="text-center text-lg font-semibold font-roboto">
                                            End of Proposal
                                        </div>
                                    }
                                    </div>
                                </div>
                            </div>
                            
                            {/* project proposal detailed descriptions - right cards */}
                            <div className='flex w-full justify-start hidden sm:block'>
                                {/* sticky top-0 required here to make proposal details follow as user scroll down */}
                                <div className="sticky top-0 w-[600px]"> 
                                {isLoading 
                                    ? <div>{setLoading}</div> 
                                    : selectedProject 
                                        ? <DesktopProposalDetails project={selectedProject} />
                                        : <DesktopProposalDetailsDefault project={sortedProjects[0]} />
                                }
                                </div>
                            </div>
                        </div>            
                        }

                    </div>
                </div>
             
                {/* {isMobile && <Proposal />} - design required for rework */}
                {isMobile && 
                <div className="grid grid-cols-1"> 
                    {isLoading ? <div>loading ...</div> : (sortedProjects?.map((project) => <MobileProposal key={project.id} project={project} showShareButton={showShareButton} setShowShareButton={setShowShareButton} />))}
                    <div className='col-span-1'>
                        {/* get the params id from DesktopProposal and pass that specific id to DesktopProposalDetails */}
                        {/* {isLoading ? <div></div> : (sortedProjects?.map((project) => <DesktopProposal key={project.id} project={project} showShareButton={showShareButton} setShowShareButton={setShowShareButton} />))} */}
                        <div className='flex justify-center text-center'>
                            {/* show load button when only sortedProjects.length is lower than total amount of project is loaded */}
                            {
                                page * pageSize < total 
                                ? 
                                <button 
                                    className='bg-white text-lg hover:bg-purple-400 hover:text-white hover:border-black text-gray-800 py-2 px-20 border font-semibold border-gray-400 rounded shadow'
                                    type='button'
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setPage(page + 1);
                                    }}
                                >
                                    Load more 
                                </button>
                                : 
                                <div className="text-center text-lg font-semibold font-roboto">
                                    End of Proposal
                                </div>
                            }
                        </div>
                    </div>
                </div>
                } 
            </div>
                
            {/*Subscribe bar to the bottom*/}
            <div className='flex bg-white z-10'>
                <div className='flex flex-col w-full text-center text-xl mt-10'>
                    <div className='mb-3'>Receive emails of new project proposal </div>
                    <SubscribeBar />
                </div>
            </div>
            <footer className='text-center'>
                &copy; 2023 Laryd. All Rights Reserved. 
            </footer>    
        </div>    
        </>
    ); 
}

export default Main;


// loading page 

const setLoading = (
    <div className="bg-white p-2 sm:p-4 sm:h-64 rounded-2xl shadow-lg flex flex-col sm:flex-row gap-5 select-none ">
      <div className="h-52 sm:h-full sm:w-72 rounded-xl bg-gray-200 animate-pulse" ></div>
      <div className="flex flex-col flex-1 gap-5 sm:p-2">
        <div className="flex flex-1 flex-col gap-3">
          <div className="bg-gray-200 w-full animate-pulse h-14 rounded-2xl" ></div>
          <div className="bg-gray-200 w-full animate-pulse h-3 rounded-2xl" ></div>
          <div className="bg-gray-200 w-full animate-pulse h-3 rounded-2xl" ></div>
          <div className="bg-gray-200 w-full animate-pulse h-3 rounded-2xl" ></div>
          <div className="bg-gray-200 w-full animate-pulse h-3 rounded-2xl" ></div>
        </div>
        <div className="mt-auto flex gap-3">
          <div className="bg-gray-200 w-20 h-8 animate-pulse rounded-full" ></div>
          <div className="bg-gray-200 w-20 h-8 animate-pulse rounded-full" ></div>
          <div className="bg-gray-200 w-20 h-8 animate-pulse rounded-full ml-auto" ></div>
        </div>
      </div>
    </div>
  )