export default function RemoteCheckbox({ isChecked, toggleChecked }) {
    return (
        <div className="flex items-center">
            <input 
                id="remote-checkbox" 
                type="checkbox" 
                checked={isChecked} 
                onChange={toggleChecked}
                value="" 
                className="w-4 h-4 text-purple-400 bg-gray-100 border-gray-300 rounded focus:ring-purple-400 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
        </div>
    )
}