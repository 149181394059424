const {
     BlobServiceClient,
}  = require("@azure/storage-blob"); 

 // the following line is added because react somehow is throwing an error that Buffer does not exist
window.Buffer = window.Buffer || require("buffer").Buffer;


function getResumeContainerClient(sas) {
    const accountName = process.env.REACT_APP_AZURE_STORAGE_ACCOUNT_NAME;
    const containerName = process.env.REACT_APP_AZURE_STORAGE_CONTAINER_NAME;
    const blobServiceUri = `https://${accountName}.blob.core.windows.net`;
    
     const blobServiceClient = new BlobServiceClient(`${blobServiceUri}?${sas}`, null);
     const resumeContainerClient = blobServiceClient.getContainerClient(containerName);

     return resumeContainerClient;
 }



 export {
     getResumeContainerClient
 }