import React, { useState } from 'react'

function InviteConfirmation({ onConfirm, onCancel }) {
    const [confirmed, setConfirmed] = useState(false)

    const handleConfirm = () => {
        if(!confirmed) {
        // the onConfirm is removed here to prevent the modal from closing.
        onConfirm()
        setConfirmed(true)
        }
    };

    // this stops the event from propagating to the parent div (the overlay)
    const preventClose = (e) => {
        e.stopPropagation()
    }

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50" onClick={onCancel}>
        <div className="absolute inset-0 bg-black opacity-50"></div>
      
        {/* Stop click events from propagating up from here */}
        <div className="bg-white p-8 rounded-lg z-10" onClick={preventClose}>
            <p className="mb-4">Confirm sending the invite?</p>
        
            <div className="flex justify-end space-x-4">
            <button 
                className="py-2 px-4 bg-gray-200 hover:bg-gray-400 rounded-md" 
                onClick={onCancel}
            >
                Close
            </button>
            
            {confirmed ? (
                <span className="py-2 px-4 bg-purple-600 text-white rounded-md">
                    <svg className={`checkmark w-6 h-6 stroke-current text-white ${confirmed ? '' : 'hidden'}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                        <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
                        <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
                    </svg>
                </span>
            ) : (
                <button 
                className="py-2 px-4 bg-purple-400 hover:bg-purple-600 text-white rounded-md" 
                onClick={handleConfirm}
                >
                Yes
                </button>
            )}
            </div>
        </div>
    </div>
  )
}

export default InviteConfirmation
