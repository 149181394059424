import React, { useState } from 'react';
import { auth } from '../../firebase/firebaseConfig';
import { useAuthState } from 'react-firebase-hooks/auth';
import Notification from './notification';
import axios from 'axios';

function InputFormThree({ formValues, setFormValues }) {
    const [user, error] = useAuthState(auth);
    const [showSuccessNotification, setShowSuccessNotification] = useState(false); // notification trigger
    const [showFailedNotification, setShowFailedNotification] = useState(false); // notification trigger

    // logic notification 
    const [edgeCaseOneMessage, setEdgeCaseOneMessage] = useState('')
    const [edgeCaseTwoMessage, setEdgeCaseTwoMessage] = useState('')
    const [edgeCaseThreeMessage, setEdgeCaseThreeMessage] = useState('')

    const endBeforStart_message = 'End date has to be after or on the same day from start date'
    const [endBeforStart, setEndBeforeStart] = useState(false); 

    const postBeforeEnd_message = 'Post date has to be before end date'
    const [postBeforeEnd, setPostBeforeEnd] = useState(false); 

    const dueBeforePost_message = 'Due date has to be before or on the same day from post date'
    const [dueBeforePost, setDueBeforePost] = useState(false);  

    let uid = user.uid; 
    let accessToken = user.accessToken; 

    //notification message 
    const success_message = "Thank you for submitting your proposal!"
    const fail_message = "An error occured, Please try again."

    //input values 
    const [formErrors, setFormErrors] = useState({
        //what is your reserach about
        name: '',
        organization: '',
        // NEW DATA POINT: correlated event 
        withEvent: '', 
        eventName: '',
        eventLink: '', 
        // original 
        title: '',
        recruitmentType: '',  
        topic1: '',
        topic2: '',
        topic3: '',
        startDate: '',
        endDate: '',
        workTimePerDay: '',
        city: '',
        state: '',
        workLocation: '',
        participantsNum: '',
        appType: '',
        appCloseDate: '',
        researchDescription: '', 
        finalOutcome: '',
        //who are you looking for 
        skill1: '',
        skill2: '',
        skill3: '',
        requiredQualification: '',
        additionalNotes: '',
        questionOne: '', 
        questionTwo: '', 
        questionThree: '',
        resultPostDate: '',
        //updated final delivery components 
        deliveryTypeOne: '',  // "Text", "Code", "Slide Presentation", "Video Recording", "N/A"                   
        deliveryTypeTwo: '', 
        deliveryTypeThree: '',
        deliveryDescription: '' ,
        //set the status of project
    });
    
    // Submit Logic 
    // count words  
    const countWords = (text) => {
        if (!text) {
            return 0; // Return 0 if the text is undefined or empty
        }

        const trimmedText = text.trim(); // Remove leading and trailing spaces
        const words = trimmedText.split(/\s+/); // Split the text by one or more whitespace characters

        return words.length;
    };

    // 2.1. max 50 words
    const isCountAbove50 = () => {
        const wordCount = countWords();
        return wordCount >= 0;
    };

    // 2.1. max 100 words
    const isCountAbove100 = () => {
        const wordCount = countWords();
        return wordCount >= 0;
    };
    
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormValues((prevValues) => ({
        ...prevValues,
        [name]: value,
        }));
    };   
    
    /**
     * Checking if form data is fileld out for save
     * @param {*} postData 
     * @returns 
     */
    function validateSaveFormData(postData) {
        const formData = postData.data

        // check if name, title, city, event name, and state are not empty
        // TODO: Change from basic alert to designed message box
        if (!formData.name || formData.name.trim() === '') {
            alert("Your Name is required.")
            return false
        }
        if (!formData.title || formData.title.trim() === '') {
            alert("Research Title is required.")
            return false
        }
        if (!formData.city || formData.city.trim() === '') {
            alert("City is required.")
            return false
        }

        return true;
    }
    
    const handleSave = async (event) => {
        event.preventDefault();

        const errors = {};
        let hasError = false;

        // 1. Error when end date is before start date 
        let startDate = new Date(formValues.startDate);
        let endDate = new Date(formValues.endDate);

        if ((startDate) > (endDate)){
            //console.log('error1')
            setEndBeforeStart(true)
            setEdgeCaseOneMessage(endBeforStart_message)
        } else {
            setEndBeforeStart(false)
            setEdgeCaseOneMessage('')
        }
        
        // 2. Error when end date is before application deadline 
        let appCloseDate = new Date(formValues.appCloseDate)

        if ((appCloseDate) > endDate){
            //console.log(appCloseDate)
            //console.log('application is after end date')
            setPostBeforeEnd(true)
            setEdgeCaseTwoMessage(postBeforeEnd_message)
        } else {
            //console.log('application is before end date')
            setPostBeforeEnd(false)
            setEdgeCaseTwoMessage('')
        }
        

        // 3. Error when application dead line is after the post date
        let resultPostDate = new Date(formValues.resultPostDate)

        if (appCloseDate >= resultPostDate){
            //console.log('application deadline is after post date')
            setDueBeforePost(true)
            setEdgeCaseThreeMessage(dueBeforePost_message)
        } else {
            //console.log('application deadline is before post date (or on  the same date)')
            setDueBeforePost(false)
            setEdgeCaseThreeMessage('')
        }


        if (hasError) {
            setFormErrors(errors);
            
            /*the following logic should be implemented when *API* is successfull. Implemented here for testing purpose*/
            setShowFailedNotification(true);
            setTimeout(() => setShowFailedNotification(false), 3000); // hide the notification after 3 seconds       
            
        } else {
            setShowSuccessNotification(true);

            setTimeout(() => setShowSuccessNotification(false), 3000); // hide the notification after 3 seconds
            console.log('Form submitted successfully!');

            //convert to json 
            let topicList = JSON.stringify([capitalizeFirstLetter(formValues.topic1), capitalizeFirstLetter(formValues.topic2), capitalizeFirstLetter(formValues.topic3)])
            let questionList = JSON.stringify([capitalizeFirstLetter(formValues.questionOne), capitalizeFirstLetter(formValues.questionTwo), capitalizeFirstLetter(formValues.questionThree)])
            let skillList = JSON.stringify([capitalizeFirstLetter(formValues.skill1), capitalizeFirstLetter(formValues.skill2), capitalizeFirstLetter(formValues.skill3)])
            let finalDelieryList = JSON.stringify([formValues.deliveryTypeOne, formValues.deliveryTypeTwo, formValues.deliveryTypeThree])
            
            //convert work location
            let recruit_type = '' 
            if (formValues.recruitmentType === "Looking for Mentor"){
                recruit_type = "find_mentor"
            } else if (formValues.recruitmentType === "Looking for Mentee") {
                recruit_type = "find_mentee"

            } else if (formValues.recruitmentType === "Looking for Team member") {
                recruit_type = "find_team"
            }

            //work_location convert 
            let location = '' 
            if(formValues.workLocation === "Remote"){
                location = "remote"
            } else if (formValues.workLocation === "Hybrid") {
                location = "hybrid"
            } else if (formValues.workLocation === "On Site") {
                location = "on_site"
            }
            
            //application type convert 
            let application_type = ''
            if(formValues.appType === "Rolling") {
                application_type = "rolling"
            } else if (formValues.appType === "Due Date") {
                application_type = 'due_date'
            }

                
            //application type convert 
            let event_binary = ''
            if(formValues.withEvent === 'no') {
                event_binary = 0
            } else if (formValues.withEvent === 'yes') {
                event_binary = 1

            }

            //application type convert 
            let convertParticipantNum = ''
            if(formValues.participantsNum === "1") {
                convertParticipantNum = 1
            } else if (formValues.participantsNum === "2") {
                convertParticipantNum = 2 
            } else if (formValues.participantsNum === '3'){
                convertParticipantNum = 3
            } else if (formValues.participantsNum === '4+'){
                convertParticipantNum = 4
            } else{                     
                convertParticipantNum = 0 
            }

            //application type convert 
            let convertCommitHours = ''
            if(formValues.workTimePerDay === "10+") {
                convertCommitHours = 10
            } else if (formValues.workTimePerDay === "20+") {
                convertCommitHours = 20 
            } else if (formValues.workTimePerDay === '30+'){
                convertCommitHours = 30
            } else if (formValues.workTimePerDay === '40+'){
                convertCommitHours = 40
            } else{                     
                convertCommitHours = 0 
            }

            // get post date 
            const timestampInSeconds = Math.floor(Date.now() / 1000);
            let new_postDate = timestampInSeconds

            //convert startDate, endDate, dueDate, postDate 
            let new_startDate = new Date(formValues.startDate).getTime() / 1000
            let new_endDate = new Date(formValues.endDate).getTime() / 1000
            let new_dueDate =  new Date(formValues.appCloseDate).getTime() / 1000
            let new_result_postDate =  new Date(formValues.resultPostDate).getTime() / 1000
            
            // default data when saving
            let postData = {
                auth: {
                    uid,
                    id_token: accessToken
                },
                data: {   
                    title: capitalizeFirstLetter(formValues.title) || '',   // user required to write their title
                    city: capitalizeFirstLetter(formValues.city) || null,
                    state: capitalizeFirstLetter(formValues.state) ||null,
                    recruitment_type: recruit_type || null,
                    app_type: application_type || null,
                    participant_num: convertParticipantNum || null,
                    work_location: location || null,
                    topics: topicList || null,
                    work_time_per_day: convertCommitHours || null,
                    description: capitalizeFirstLetter(formValues.researchDescription) || null,
                    required_qualifications: capitalizeFirstLetter(formValues.requiredQualification) || null,
                    questions: questionList || null,
                    notes: capitalizeFirstLetter(formValues.additionalNotes) || null,
                    owner_uid: uid,
                    name: capitalizeFirstLetter(formValues.name) || '',     // user required to write their name 
                    organization: capitalizeFirstLetter(formValues.organization) || null, 
                    skills: skillList || null, 
                    final_delivery_types: finalDelieryList || null,
                    final_delivery_description: capitalizeFirstLetter(formValues.deliveryDescription) || null,
                    with_event: event_binary || 0,                          // user required to choose yes or no
                    event_name: formValues.eventName || null,               // user required to give an input
                    status: 'saved',
                    event_link: formValues.eventLink || null,
                    start_date: new_startDate || null,
                    end_date: new_endDate || null,
                    app_close_date: new_dueDate || null, 
                    app_result_post_date: new_result_postDate || null, 
                }
            }

            if (!validateSaveFormData(postData)) {
                return; 
            }

            try {
                let response = await axios.post(`${process.env.REACT_APP_SERVERURL}/project/save`, postData, {
                    headers: { 'Content-Type': 'application/json' }
                });
                if (response.result === "success") {
                    alert("Successfully Applied")
                } else {
                    {/* alert message */ }
                    alert("There was an error submitting application")
                }
    
                console.log(response.data);
            } catch (error) {
                console.log('There an error:', error.response.status);
                console.log('More about error', error.response.data)
                alert(error)
            }
        }
    };
      
    //check when for empty inputs 
    const handleSubmit = async (event) => {
        event.preventDefault();

        const errors = {};
        let hasError = false;

        // Check for empty inputs and set errors
        // logic checked
        Object.entries(formValues).forEach(([key, value]) => {
        if (value.trim() === '') {
            errors[key] = 'Please fill out this field.';
            hasError = true;
        } 
        });

        // 1. Error when end date is before start date 
        let startDate = new Date(formValues.startDate);
        let endDate = new Date(formValues.endDate);

        if ((startDate) > (endDate)){
            //console.log('error1')
            setEndBeforeStart(true)
            setEdgeCaseOneMessage(endBeforStart_message)
        } else {
            setEndBeforeStart(false)
            setEdgeCaseOneMessage('')
        }
        
        // 2. Error when end date is before application deadline 
        let appCloseDate = new Date(formValues.appCloseDate)

        if ((appCloseDate) > endDate){
            //console.log(appCloseDate)
            //console.log('application is after end date')
            setPostBeforeEnd(true)
            setEdgeCaseTwoMessage(postBeforeEnd_message)
        } else {
            //console.log('application is before end date')
            setPostBeforeEnd(false)
            setEdgeCaseTwoMessage('')
        }
        

        // 3. Error when application dead line is after the post date
        let resultPostDate = new Date(formValues.resultPostDate)

        if (appCloseDate >= resultPostDate){
            //console.log('application deadline is after post date')
            setDueBeforePost(true)
            setEdgeCaseThreeMessage(dueBeforePost_message)
        } else {
            //console.log('application deadline is before post date (or on  the same date)')
            setDueBeforePost(false)
            setEdgeCaseThreeMessage('')
        }
    

        if (hasError) {
            setFormErrors(errors);
            
            /*the following logic should be implemented when *API* is successfull. Implemented here for testing purpose*/
            setShowFailedNotification(true);
            setTimeout(() => setShowFailedNotification(false), 3000); // hide the notification after 3 seconds       
            
        } else {
            setShowSuccessNotification(true);

            setTimeout(() => setShowSuccessNotification(false), 3000); // hide the notification after 3 seconds
            console.log('Form submitted successfully!');

            //convert to json 
            let topicList = JSON.stringify([capitalizeFirstLetter(formValues.topic1), capitalizeFirstLetter(formValues.topic2), capitalizeFirstLetter(formValues.topic3)])
            let questionList = JSON.stringify([capitalizeFirstLetter(formValues.questionOne), capitalizeFirstLetter(formValues.questionTwo), capitalizeFirstLetter(formValues.questionThree)])
            let skillList = JSON.stringify([capitalizeFirstLetter(formValues.skill1), capitalizeFirstLetter(formValues.skill2), capitalizeFirstLetter(formValues.skill3)])
            let finalDelieryList = JSON.stringify([formValues.deliveryTypeOne, formValues.deliveryTypeTwo, formValues.deliveryTypeThree])
            
            //convert work location
            let recruit_type = '' 
            if (formValues.recruitmentType === "Looking for Mentor"){
                recruit_type = "find_mentor"
            } else if (formValues.recruitmentType === "Looking for Mentee") {
                recruit_type = "find_mentee"

            } else if (formValues.recruitmentType === "Looking for Team member") {
                recruit_type = "find_team"
            }

            //work_location convert 
            let location = '' 
            if(formValues.workLocation === "Remote"){
                location = "remote"
            } else if (formValues.workLocation === "Hybrid") {
                location = "hybrid"
            } else if (formValues.workLocation === "On Site") {
                location = "on_site"
            }
            
            //application type convert 
            let application_type = ''
            if(formValues.appType === "Rolling") {
                application_type = "rolling"
            } else if (formValues.appType === "Due Date") {
                application_type = 'due_date'
            }

                
            //application type convert 
            let event_binary = ''
            if(formValues.withEvent === 'no') {
                event_binary = 0
            } else if (formValues.withEvent === 'yes') {
                event_binary = 1

            }

            //application type convert 
            let convertParticipantNum = ''
            if(formValues.participantsNum === "1") {
                convertParticipantNum = 1
            } else if (formValues.participantsNum === "2") {
                convertParticipantNum = 2 
            } else if (formValues.participantsNum === '3'){
                convertParticipantNum = 3
            } else if (formValues.participantsNum === '4+'){
                convertParticipantNum = 4
            } else{                     
                convertParticipantNum = 0 
            }

            //application type convert 
            let convertCommitHours = ''
            if(formValues.workTimePerDay === "10+") {
                convertCommitHours = 10
            } else if (formValues.workTimePerDay === "20+") {
                convertCommitHours = 20 
            } else if (formValues.workTimePerDay === '30+'){
                convertCommitHours = 30
            } else if (formValues.workTimePerDay === '40+'){
                convertCommitHours = 40
            } else{                     
                convertCommitHours = 0 
            }

            // get post date 
            const timestampInSeconds = Math.floor(Date.now() / 1000);
            let new_postDate = timestampInSeconds

            //convert startDate, endDate, dueDate, postDate 
            let new_startDate = new Date(formValues.startDate).getTime() / 1000
            let new_endDate = new Date(formValues.endDate).getTime() / 1000
            let new_dueDate =  new Date(formValues.appCloseDate).getTime() / 1000
            let new_result_postDate =  new Date(formValues.resultPostDate).getTime() / 1000
            
            // TODO: re-format the data to match w/ updated migrations
            let postData = {
                auth: {
                    uid,
                    id_token: accessToken
                },
                data: {   
                    title: capitalizeFirstLetter(formValues.title),
                    city: capitalizeFirstLetter(formValues.city),
                    state: capitalizeFirstLetter(formValues.state),
                    recruitment_type: recruit_type,
                    app_type: application_type,
                    participant_num: convertParticipantNum, // need an update
                    work_location: location, 
                    topics: topicList,
                    start_date: new_startDate,
                    end_date: new_endDate,
                    work_time_per_day: convertCommitHours, // need an update
                    description: capitalizeFirstLetter(formValues.researchDescription),
                    required_qualifications: capitalizeFirstLetter(formValues.requiredQualification),
                    questions: questionList,
                    notes: capitalizeFirstLetter(formValues.additionalNotes),
                    owner_uid: uid,
                    name: capitalizeFirstLetter(formValues.name), 
                    organization: capitalizeFirstLetter(formValues.organization), 
                    skills: skillList, 
                    app_close_date: new_dueDate, 
                    app_result_post_date: new_result_postDate, 
                    // updated_at: new_postDate, // not existing in db
                    final_delivery_types: finalDelieryList,
                    final_delivery_description: capitalizeFirstLetter(formValues.deliveryDescription),
                    with_event: event_binary, 
                    event_name: formValues.eventName,
                    event_link: formValues.eventLink,
                    status: 'open',
                }
            }

            try {
                let response = await axios.post(`${process.env.REACT_APP_SERVERURL}/project/upload`, postData, {
                    headers: { 'Content-Type': 'application/json' }
                });
                if (response.result === "success") {
                    {/* email notification + navigation + alert message  -- jw -> daniel */}
                    alert("Successfully Applied")
                } else {
                    {/* alert message */ }
                    alert("There was an error submitting application")
                }
    
                console.log(response.data);
            } catch (error) {
                console.log('There an error:', error.response.status);
                console.log('More about error', error.response.data)
            }
            setFormErrors({});
        }
    };

    return (
    <>   
    <form onSubmit={handleSubmit}>
        <div className="flex flex-wrap -mx-3 mb-2">
            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-3">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="">
                    Delivery Object 1
                </label>
                <div className="relative">
                    <select className={`block appearance-none w-full bg-gray-200 border ${formErrors.state ? 'border-red-500' : 'border-gray-200'}  text-gray-700 py-3 px-4 pr-8 mb-1 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
                    id="grid-delivery-one"
                    name="deliveryTypeOne"
                    type="option"
                    value={formValues.deliveryTypeOne}
                    onChange={handleChange}>
                    <option>Select</option> 
                    <option>Text</option> 
                    <option>Code</option> 
                    <option>Slide Presentation</option> 
                    <option>Video Recording</option> 
                    <option>Other</option> 
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                    </div>
                    {formErrors.deliveryTypeOne && (<p className="text-red-500 text-xs italic">Please fill out this field.</p>)} 
                </div>
            </div>

            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-3">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-admission-type">
                Delivery Object 2
                </label>
                <div className="relative">
                    <select className={`block appearance-none w-full bg-gray-200 border ${formErrors.state ? 'border-red-500' : 'border-gray-200'} text-gray-700 py-3 px-4 pr-8  mb-1 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
                    id="grid-delivery-two"
                    type="option"
                    name="deliveryTypeTwo"
                    value={formValues.deliveryTypeTwo}
                    onChange={handleChange}>
                        <option>Select</option> 
                        <option>Text</option> 
                        <option>Code</option> 
                        <option>Slide Presentation</option> 
                        <option>Video Recording</option> 
                        <option>Other</option>
                        <option>N/A</option> 
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                        <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                    </div>
                    {formErrors.deliveryTypeTwo && (<p className="text-red-500 text-xs italic">Please fill out this field.</p>)} 
                </div>
            </div>

            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-3">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="appCloseDate">
                    Delivery Object 3
                </label>
                <div className="relative">
                    <select className={`block appearance-none w-full bg-gray-200 border ${formErrors.state ? 'border-red-500' : 'border-gray-200'}  text-gray-700 py-3 px-4 pr-8 mb-1 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
                    id="grid-delivery-three"
                    name="deliveryTypeThree"
                    type="option"
                    value={formValues.deliveryTypeThree}
                    onChange={handleChange}>
                        <option>Select</option> 
                        <option>Text</option> 
                        <option>Code</option> 
                        <option>Slide Presentation</option> 
                        <option>Video Recording</option> 
                        <option>Other</option>
                        <option>N/A</option> 
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                    </div>
                    {formErrors.deliveryTypeThree && (<p className="text-red-500 text-xs italic">Please fill out this field.</p>)} 
                </div>  
            </div>
        

            <div className="w-full px-3 mb-6 md:mb-3">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-description">
                    Final Outcome / Delivery Description
                </label>
                <textarea
                    style={{ resize: 'none', overflowY: "auto"}} 
                    className={`apearance-none block w-full bg-gray-200 text-gray-700 border ${formErrors.deliveryDescription ? 'border-red-500' : 'border-gray-200'}  rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
                    id="grid-description"
                    name='deliveryDescription'
                    value={formValues.deliveryDescription}
                    placeholder="Describe final outcome and delivery description of your project"
                    rows={10}
                    onChange={handleChange}
                ></textarea>
                {formErrors.deliveryDescription && (<p className="text-red-500 text-xs italic">{formErrors.deliveryDescription}</p>)}
                {!isCountAbove100() && (
                    <p className="text-black-500 text-xs italic">
                        {(() => {
                            let wordsLeft = 100 - countWords(formValues.deliveryDescription);
            
                            return wordsLeft > 0 ? `${wordsLeft} more words to go!` :
                            wordsLeft === 0 ? 'yay!' :
                            'wow!'
                        })()}          
                    </p>
                )}     
            </div>

            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-3">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="resultPostDate">
                Application Result Post Date
                </label>
                <input
                className={`appearance-none block w-full bg-gray-200 text-gray-700 border ${formErrors.resultPostDate ? 'border-red-500' : 'border-gray-200'}  rounded py-2 px-4 mb-1 leading-tight focus:outline-none focus:bg-white`}
                id="resultPostDate"
                type="date"
                name="resultPostDate" 
                value={formValues.resultPostDate}
                onChange={handleChange}
                />
                {formErrors.resultPostDate && (<p className="text-red-500 text-xs italic">Select your result post date.</p>)}            
            </div>   
        </div>

        <div className='flex justify-center mb-5'>
            {endBeforStart && (<p className="text-black text-md italic">*ERROR: { edgeCaseOneMessage }.</p>)}   
            {postBeforeEnd && (<p className="text-black text-md italic">*ERROR: { edgeCaseTwoMessage }.</p>)} 
            {dueBeforePost && (<p className="text-black text-md italic">*ERROR: { edgeCaseThreeMessage }.</p>)}          
        </div>

        {/* ui wise there are a lot more to consider*/}
        <div className='flex flex-row justify-center'>
            {showSuccessNotification && <Notification message={ success_message }/>}
            {showFailedNotification && <Notification message={ fail_message }/>}
        </div>
        <div class="flex justify-center space-x-4">
            <button className="w-[120px] h-[45px] bg-gray-400 hover:bg-[#584C7A] rounded-[100px] mt-[2rem] type=myaccount">
                <p className="text-center text-white text-[15px] font-medium font-roboto">
                    Save
                </p>
            </button>
            <button className="w-[120px] h-[45px] bg-theme-purple hover:bg-[#584C7A] rounded-[100px] mt-[2rem] type=myaccount">
                <p className="text-center text-white text-[15px] font-medium font-roboto">
                    Submit
                </p>
            </button>
        </div>

    </form>       
    </>
  );
};

export default InputFormThree;


function capitalizeFirstLetter(inputString) {
  if (typeof inputString !== 'string' || inputString.length === 0) {
    return inputString;
  }

  return inputString[0].toUpperCase() + inputString.slice(1);
}