import React, { useEffect, useState, useRef } from "react";
import MainNavBar from './nav-bar';
import UserPageHeader from "../main/header";
import MainDashboard from "./dashboard";
import ApplicationStatus from './application';
import MyProposal from "./decisionPage/my-proposal";
import Settings from './settings';
import LogOutDashboard from "./logoutpage";
import { auth, checkSession, onAuthStateChanged } from '../../firebase/firebaseConfig';
import { useAuthState } from 'react-firebase-hooks/auth';
import axios from 'axios';

function UserPage({allProject}) {

  //console.log(allProject)
  const [applicationList, setApplicationList] = useState([]) // for main
  const [user, loading, error] = useAuthState(auth);
  const [activeTab, setActiveTab] = useState('dashboard');
  const [projectLoading, setProjectLoading] = useState(true)
  const [savedProject, setSavedProject] = useState([])
  const [currentStep, setCurrentStep] = useState(1);
  const [lineWidth, setLineWidth] = useState(0);
  const [lineOffset, setLineOffset] = useState(0);
  const stepRefs = useRef([]);

  useEffect(() => {
      if (stepRefs.current[currentStep-1]) {
          setLineWidth(stepRefs.current[currentStep-1].offsetWidth);
          setLineOffset(stepRefs.current[currentStep-1].offsetLeft);
      }
  }, [currentStep]);

  const goToStep = (step) => setCurrentStep(step);

  checkSession()

  useEffect(() => {
    if (user) {
      const uid = user.uid;
      const id_token = user.accessToken;
      // please delete this console.log - This id token is to test API call
      // console.log("id token:", id_token)
      getApp(uid, id_token); // Pass these values as parameters
      // getSavedProject(uid, id_token) - for save api
    }
  }, [user]); // Only run this effect when the `user` object changes

  // for save api
  // const getSavedProject = async (uid, id_token) => {
  //   setProjectLoading(true);

  //   try {
  //       if (user) {
  //           const response = await axios.post(`${process.env.REACT_APP_SERVERURL}/project/get_saved_projects`, 
  //             { 
  //               auth: { uid, id_token },
  //               data: {}
  //             }, 
  //             { 
  //               headers: { 'Content-Type': 'application/json' }
  //             } 
  //           );

  //           let savedProjects = response.data.data;
  //           console.log(savedProjects)
  //           setSavedProject(savedProjects);
  //       } else {
  //           // Handle the case where the user is not logged in
  //           console.error('User not logged in');
  //       }
  //   } catch (err) {
  //     console.log(err)
  //       console.error('Error: ', err)
  //   }  finally {
  //     setProjectLoading(false);
  //   }
  // }  

  // calls for user's application 
  const getApp = async (uid, id_token) => {

    const application_request = {
      auth: {
        id_token: id_token,
        uid: uid
      },
      data: {
        owner_uid: uid
      }
    }
    
    try {
        const response = await axios.post(`${process.env.REACT_APP_SERVERURL}/application/get_applications`, application_request)
        let applicationData = response.data.data
        // console.log("Checking core application data")
        // console.log(applicationData)
        setApplicationList(applicationData); // for user page
    } catch (err) {
      console.log(err)
    }  
  }  

  if (!user) {
      return (
        <LogOutDashboard />
      );

  }

  if(user){
    const uid = user.uid 
    const myproject = allProject ? allProject.filter(project => project.owner_uid.includes(uid)) : [];

    const recordDisplay = () => {
      if (activeTab === 'dashboard') {
        return <MainDashboard myproject={myproject} applicationList={applicationList} allProject={allProject}/>;
      }
      if (activeTab === 'application-status') {
        return <ApplicationStatus applicationList={applicationList} allProject={allProject}/>;
      }
      if (activeTab === 'myproposal') {
        return <MyProposal myproject={myproject}/>;
      }
      if (activeTab === 'settings') {
        return <Settings />;
      }
    };

    const steps = [
      { number: 1, label: 'All Applications' },
      { number: 2, label: 'My Proposals' },
    ];


    const renderStepNavigation = () => {
      return (
          <>
              <div className="flex flex-col w-[63%] relative">
                  <div className="flex justify-start space-x-4">
                      {steps.map((step, index) => (
                          <button
                              key={step.number}
                              ref={el => stepRefs.current[index] = el}
                              onClick={() => goToStep(step.number)}
                              className={`text-[16px] font-medium font-roboto ${currentStep === step.number ? 'text-black' : 'text-[#A3A2A4]'}`}
                          >
                              {step.label}
                          </button>
                      ))}
                  </div>
                  <div className="relative">
                      <hr className="absolute bg-[#6750A4] h-[3px]" style={{ width: `${lineWidth}px`, marginLeft: `${lineOffset}px` }} />
                      <hr className="bg-[#DFDFEF] h-[3px] w-full" />
                  </div>
              </div>
          </>
      );
    };

    const renderStep = () => {
      switch(currentStep) {
        case 1:
          return (
              <>
                  {/* <div className='flex justify-center sm:w-[100%] w-[0%] mt-[2rem]'>
                      <div className="flex justify-center items-center sm:w-[70%]"> */}
                          <MainDashboard myproject={myproject} applicationList={applicationList} allProject={allProject} />
                      {/* </div>
                  </div> */}
              </>
          );
        case 2:
          return (
              <>
                  {/* <div className='flex justify-center sm:w-[100%] w-[0%] mt-[2rem]'>
                      <div className="flex justify-center items-center sm:w-[70%]"> */}
                          <MyProposal myproject={myproject} /> 
                      {/* </div>
                  </div> */}
              </>
          );
      }
    };

    return (
      <div>
        <div className="pb-[4rem] pt-[3rem]">
          <UserPageHeader />
        </div>
        <div className="flex justify-center mt-[3rem]">
          {renderStepNavigation()}
        </div>  
        {renderStep()}
        <div className="flex flex-col">
          {/* <MainNavBar setActiveTab={setActiveTab} activeTab={activeTab} /> */}
          {/* <div style={{ flexGrow: 1 }}>{recordDisplay()}</div> */}
        </div>
      </div>
    );
  }
}

export default UserPage;
