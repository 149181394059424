import React, { useState } from 'react';
import UserPageHeader from './header'
function LogOutDashboard () {

    return (
        <>        
        <div className='w-full'>
            <UserPageHeader />
            <div className="flex text-3xl h-12 p-12 items-center border-b-2 border-black text-white bg-black"> 
                <b>My Dashboard</b> 
            </div>
            
            <div className="max-w-1440 mx-auto"> {/* stopper */}
                <div className='flex mt-20 justify-center'>
                    {setLoading}
                </div>
            </div>
        </div>
        
        <footer className='text-center'>
            &copy; 2023 Laryd. All Rights Reserved. 
        </footer>    
        </>
    );
}

export default LogOutDashboard;

const setLoading = (
    <div className="bg-white p-2 sm:p-4 sm:h-64 rounded-2xl shadow-lg flex flex-col sm:flex-row gap-5 select-none ">
      <div className="h-52 sm:h-full sm:w-72 rounded-xl bg-gray-200 animate-pulse" ></div>
      <div className="flex flex-col flex-1 gap-5 sm:p-2">
        <div className="flex flex-1 flex-col gap-3">
          <div className="bg-gray-200 w-full animate-pulse h-14 rounded-2xl" ></div>
          <div className="bg-gray-200 w-full animate-pulse h-3 rounded-2xl" ></div>
          <div className="bg-gray-200 w-full animate-pulse h-3 rounded-2xl" ></div>
          <div className="bg-gray-200 w-full animate-pulse h-3 rounded-2xl" ></div>
          <div className="bg-gray-200 w-full animate-pulse h-3 rounded-2xl" ></div>
        </div>
        <div className="mt-auto flex gap-3">
          <div className="bg-gray-200 w-20 h-8 animate-pulse rounded-full" ></div>
          <div className="bg-gray-200 w-20 h-8 animate-pulse rounded-full" ></div>
          <div className="bg-gray-200 w-20 h-8 animate-pulse rounded-full ml-auto" ></div>
        </div>
      </div>
    </div>
  )
