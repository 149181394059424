import ShareButton from "./ui/shareButton";
import React, { useState, useEffect } from "react"; 
import { Link } from "react-router-dom";
import DesktopProposalLoading from './loading'
import ShareIcons from "./ui/shareIcons";

// Temp project icon
import project_icon from '../../img/example_project_icon.png';

function DesktopProposal({ project, isFirst, showShareButton, setShowShareButton }) {
    
    // using project id as path
    const proposalLink = `/${project.id}`

    const initialColor = isFirst ? 'bg-white' : 'bg-purple-200';  // If it is the first proposal, make the initial color white
    const [bgColor, setBgColor] = useState('bg-white');  // Initial color
    const handleOnClick = () => {
        setBgColor(prevColor => prevColor === 'bg-white' || prevColor === 'bg-white' ? 'bg-purple-200' : initialColor);  // Toggle color on click
    }

    let event_link = '' 
    let eventExist = ''
    //check if there is an event 
    if (project.with_event = 1){
        event_link = project.event_link
        eventExist = true
    } else if (project.with_event = 0){
        event_link = ''
        eventExist = false
    } else {
        event_link = 'error'
    }

    useEffect(() => {
        if (showShareButton) {
            document.body.style.overflow = 'hidden';  // Disable scrolling
        } else {
            document.body.style.overflow = 'auto';    // Enable scrolling
        }

        return () => {
            document.body.style.overflow = 'auto';    // Re-enable scrolling on cleanup
        };
    }, [showShareButton]);

    return(
        <>
            <Link to={proposalLink} key={project.id}>
                <div onClick={handleOnClick} className={`w-[100%] h-[185px] border border-gray-200 hover:bg-fuchsia-100 ${bgColor} mb-[12px]`}>
                    <div className='flex flex-col-2 align-center m-[0.5rem]'>
                        <div>
                            <img src={project_icon} alt="project icon" className="w-[59px] h-[59px]" />
                        </div>
                        <div className="flex flex-col w-[90%] ml-[1rem] space-y-[0.1rem]">
                            {/* <div className='flex flex-row text-2xl justify-between'>
                            <b className="max-w-[90%]">{(project.title)}</b>
                            {showShareButton && (
                            <div 
                                onClick={() => {
                                    setShowShareButton(false); // clicking the background makes showShareButton to false, so that the user can unlock from freezed screen
                                    document.body.style.overflow = 'auto';
                                }} 
                                className="fixed inset-0 flex justify-center items-center z-50 bg-black bg-opacity-20"
                            >
                                <div 
                                    onClick={(e) => e.stopPropagation()} 
                                    className="rounded p-4 bg-white flex space-x-4"
                                >
                                    <ShareIcons />  
                                </div>
                            </div>
                            )}
                            <div className='flex flex-row ' onClick={() => setShowShareButton(true)}><ShareButton /></div>
                            </div> */}
                            <p 
                                className="text-black text-base font-bold font-roboto h-[40px] mb-[0.5rem]"
                            >
                                {project.title.substring(0, 65)}
                            </p>
                            <p className="text-black text-base font-normal font-roboto' leading-tight tracking-tight">
                                {project.organization}
                            </p>    
                            <p className="text-stone-500 text-[16px] font-light font-roboto leading-tight tracking-tight">
                                {project.city}, {project.state}
                            </p>
                            <div className="flex flex-row space-x-[0.2rem]">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM18.36 16.83C16.93 15.09 13.46 14.5 12 14.5C10.54 14.5 7.07 15.09 5.64 16.83C4.62 15.49 4 13.82 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 13.82 19.38 15.49 18.36 16.83ZM8.5 9.5C8.5 7.56 10.06 6 12 6C13.94 6 15.5 7.56 15.5 9.5C15.5 11.44 13.94 13 12 13C10.06 13 8.5 11.44 8.5 9.5Z" fill="#666666"/>
                                </svg>
                                <p className="flex items-center text-stone-500 text-[15px] font-normal font-roboto leading-tight tracking-tight">
                                    Posted By Paul Nam, Associate Teaching Professor (CSE)
                                </p>
                            </div>

                            <div>
                                <div className="flex w-[155px] h-[24px] rounded-[100px] border border-zinc-500 justify-center items-center inline-flex mt-[0.6rem] sm:mt-[1.5rem]">
                                    <p className="text-center text-slate-500 text-xs font-medium font-roboto overflow-hidden text-overflow white-space-nowrap">Looking for {convertPartnerType(project.recruitment_type)}</p>
                                </div>
                            </div>
                            {/* Posted date - will need this in detailed card */}
                            {/* <div className='flex justify-between text-md mt-2'>
                                <div className="text-md">Posted: {convertMMDDYY(project.updated_at)}</div>
                                { eventExist && <div className="pl-[3rem]"><b>Involves Event</b></div>} 
                            </div> */}

                            {/* <div className='flex justify-evenly mt-2'>
                                <div>
                                    Looking for {convertPartnerType(project.recruitment_type)}
                                </div>
                                <div>|</div>
                                <div>
                                    {project.city}
                                </div>
                                <div>|</div>
                                <div>
                                <p>Due: {convertMMDDYY(project.end_date)}</p>

                                </div>
                            </div> */}
                            {/* Number of applications */}
                            {/* <div className="flex justify-center"> {project.application_count} Application{checkSinglePlural(project.application_count)} / {project.participant_num} Spot{checkSinglePlural(project.participant_num)} - {project.status}</div> */}
                        </div>
                    </div>       
                </div>
            </Link>
        </>
    );
}

export default DesktopProposal; 


//converting functions

// converting unix time stamp to actual time
const convertMMDDYY = function unix_timeConverter(unix_timestamp) {
    var a = new Date(unix_timestamp * 1000)
    var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    var time = month + ' '+ date + ', ' + year;
    return time;
}

// converting Looking for variable 
function convertPartnerType(project_type){
    let convertedType = 'Error'
    if (project_type === "find_mentor") {
        convertedType = "Mentor"
    } else if (project_type === "find_mentee" ) {
        convertedType = "Mentee"
    } else if (project_type === "find_team" ) {
        convertedType = "Team Member"
    }
    return convertedType
}


function checkSinglePlural(count_input){
    let metric = ''
    if (count_input > 1){
        metric = '(s)'
        return metric
    } else {
        return metric 
    }
}