export default function SearchComponent() {
return (
    <form className="relative mb-3 right-2 w-1/4 lg:bottom-8 md:bottom-8">
      <div className="relative">
        <input
          className="w-full py-1 pl-2 pt-2 bg-transparent text-gray-500 border-b-2 outline-none"
          type="text"
          placeholder="Search"
          // onChange={handleInputChange}
          // value={localSearchTerm}
        />
      </div>
    </form>
  )
}