// no longer used

import logo from '../../img/layrd-logo.png';
import { auth } from '../../firebase/firebaseConfig';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Link } from 'react-router-dom';
import { useState } from 'react';

function ProposalHeader() {
    const [user, loading, error] = useAuthState(auth);
    const [isOpen, setIsOpen] = useState(false);

    // Function to handle signout
    const handleSignout = async () => {
        try {
            await auth.signOut();
        } catch (err) {
            console.error("Signout Error", err);
        }
    };
    
    return(
        <header className='flex justify-between bg-white text-xl border-b border-black py-2'>
    
        <nav className="flex justify-between w-full mx-10">
            <div>
                <ul className="flex items-center">
                    <li>
                        <Link to="/">
                        <img src={logo} alt="logo" className='h-12 my-auto'/> 
                        </Link>
                    </li>
                    <li className='bg-white text-lg hover:bg-purple-400  text-gray-800 py-2 px-3 border font-semibold border-gray-400 rounded shadow ml-3 my-auto type=postproposal'>
                        <a href='https://blockresearch.gitbook.io/blockresearch/'  target="_blank" rel="noreferrer">Docs</a>
                    </li>
                </ul>
            </div>
            <div>  
                <ul className='flex'>
                <li className='bg-white text-lg hover:bg-purple-400 hover:text-white hover:border-black text-gray-800 py-2 px-3 border font-semibold border-gray-400 rounded shadow my-auto type=myaccount'>
                    <div 
                        className="cursor-pointer" 
                        onMouseEnter={() => setIsOpen(true)}
                    >              
                        {isOpen && (
                        <div 
                            className="absolute top-10 right-[2.25rem] mt-6 w-50 border bg-white text-black py-2 rounded-md shadow-lg" 
                        >   
                            <div onMouseLeave={() => setIsOpen(false)}>                                   
                                <div className="hover:bg-gray-200 cursor-pointer px-3 py-1">                         
                                    {
                                        loading ?
                                        (<div>Loading</div>) :
                                        user ? 
                                        (   
                                            <a href="mailto:jinwooverse@layrd.xyz" target="_blank" rel="noopener noreferrer">
                                            Send Feedback
                                            </a>
                                        ) 
                                        :
                                        (
                                            <div className="hover:bg-gray-200 cursor-pointer px-3 py-1">                         
                                            <a href="mailto:jinwooverse@layrd.xyz" target="_blank" rel="noopener noreferrer">
                                            Send Feedback
                                            </a>
                                            </div>
                                        )                                     
                                    }
                                </div>

                                <div className="hover:bg-gray-200 cursor-pointer px-3 py-1">                         
                                    {
                                        loading ?
                                        (<div>Loading</div>) :
                                        user ? 
                                        ( <div className="hover:bg-gray-200 cursor-pointer px-3 py-1" onClick={handleSignout}>Log out</div>) 
                                        :
                                        (
                                        <Link to="/auth">
                                        <div className="hover:bg-gray-200 cursor-pointer px-3 py-1">                         
                                        Sign up
                                        </div></Link>)
                                    }
                                </div>

                            </div>
                        </div>
                    )}  
                    {
                        loading ? 
                        (<div>Loading...</div>) :
                        user ? 
                        (<Link to="/user">My Account</Link>) :
                        (<Link to="/auth">Sign in</Link>)
                    }
                    </div>
                </li>
                </ul>
            </div>
        </nav>
        </header>

    )
}
export default ProposalHeader;

