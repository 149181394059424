import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import project_icon from '../../img/example_project_icon.png';

function MainDashboard({ myproject }) {
    
    // creating useState for each table so that we can control them independently
    const [selectProjectType, setSelectProjectType] = useState('all');

    /* Sort combined data by date
    combinedData.sort((a, b) => {
        const dateA = a.type === 'project' ? new Date(a.data.end_date) : new Date(a.data.post_date);
        const dateB = b.type === 'project' ? new Date(b.data.end_date) : new Date(b.data.post_date);
        return dateB - dateA;
    });
    */ 

    const project_length = myproject.length;  // Replace 'cards' with your actual array name holding the data

    return (
        <>             
            <div className="max-w-1440 mx-auto min-h-screen"> {/* stopper */}
                {/*show activities and notification*/}
                <div className="flex flex-col p-0 sm:p-10 mx-10 justify-evenly mt-2 mb-5"> 
                    {/* sample card compoenent */}
                    <div className='flex flex-col py-5'>
                        <div className='flex w-full flex-wrap justify-start md:ml-[2.5rem] gap-6'>
                        {
                            selectProjectType ==='all' ?
                            (
                                <>
                                {myproject.map((project, index) => {
                                        return <ProjectCard key={index} project={project} />;

                                })}
                                </>
                            )  :
                            selectProjectType ==='draft' ?
                            (
                                <>
                                    There is no draft. 
                                </>
                            ) :
                            selectProjectType ==='active' ?
                            (
                                <>
                                    <>
                                    {myproject.map((project, index) => {
                                        const timestampInSeconds = Math.floor(Date.now() / 1000);

                                        if ( (project.end_date > timestampInSeconds)) {
                                            {/* if between start date and end date*/}                                
                                            return <ProjectCard key={index} project={project} />;
                                        } 
                                    })}                    
                                    </>
                                </>
                            ) :
                            (
                                <> {/* inactive */}
                                    <>
                                    {myproject.map((project, index) => {
                                        const timestampInSeconds = Math.floor(Date.now() / 1000);

                                        if ( (project.end_date < timestampInSeconds)) {
                                            {/* if between start date and end date*/}                 
                                            return <ProjectCard key={index} project={project} />;
                                        } 
                                    })}                    
                                    </>                    
                                </>
                            )
                        }
                        </div>
                        <div className='flex justify-center text-center mt-5'>
                            {/* display logic*/}
                            {myproject > 9 ? 
                            <div className="flex items-center justify-center w-[200px] h-10 bg-[#6750A4] hover:bg-[#584C7A] rounded-[100px] mt-[2rem] md:mr-[13rem]" type='load'>
                                <p className="text-center text-white text-sm font-medium font-roboto">
                                    View More Proposal
                                </p>
                            </div>
                            : 
                            <div className="flex items-center justify-center w-[200px] h-10 bg-[#6750A4] hover:bg-[#584C7A] rounded-[100px] mt-[2rem] md:mr-[13rem]">
                                <p className="text-center text-white text-sm font-medium font-roboto">
                                    Proposal all Displayed
                                </p>
                            </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            
            <footer className='text-center '>
                &copy; 2023 Laryd. All Rights Reserved. 
            </footer>     
        </>          
    );
}

export default MainDashboard;


/**
 * the function takes single project and returns single card compoenent 
 * @param {*} project  
 * @returns single card with project information 
 */
const ProjectCard = ({ project, isFirst }) => {
    const initialColor = isFirst ? 'bg-white' : 'bg-purple-200';  // If it is the first proposal, make the initial color white
    const [bgColor, setBgColor] = useState('bg-white');  // Initial color
    const handleOnClick = () => {
        setBgColor(prevColor => prevColor === 'bg-white' || prevColor === 'bg-white' ? 'bg-purple-200' : initialColor);  // Toggle color on click
    }

    // using project id as path
    const proposalLink = `/proposal/${project.id}`

    return (
        <>
            <Link to={proposalLink} key={project.id}>
                <div onClick={handleOnClick} className={`w-[100%] h-[185px] border border-gray-200 hover:bg-fuchsia-100 ${bgColor}`}>
                    <div className='flex align-center m-[0.5rem]'>
                        <div>
                            <img src={project_icon} alt="project icon" className="w-[59px] h-[59px]" />
                        </div>
                        <div className="flex flex-col w-[90%] ml-[1rem] space-y-[0.1rem]">
                            {/* <div className='flex flex-row text-2xl justify-between'>
                            <b className="max-w-[90%]">{(project.title)}</b>
                            {showShareButton && (
                            <div 
                                onClick={() => {
                                    setShowShareButton(false); // clicking the background makes showShareButton to false, so that the user can unlock from freezed screen
                                    document.body.style.overflow = 'auto';
                                }} 
                                className="fixed inset-0 flex justify-center items-center z-50 bg-black bg-opacity-20"
                            >
                                <div 
                                    onClick={(e) => e.stopPropagation()} 
                                    className="rounded p-4 bg-white flex space-x-4"
                                >
                                    <ShareIcons />  
                                </div>
                            </div>
                            )}
                            <div className='flex flex-row ' onClick={() => setShowShareButton(true)}><ShareButton /></div>
                            </div> */}
                            <p 
                                className="text-black text-base font-bold font-roboto h-[40px] mb-[0.5rem]"
                            >
                                {project.title.substring(0, 40)}
                            </p>
                            <p className="text-black text-base font-normal font-roboto' leading-tight tracking-tight">
                                {project.organization}
                            </p>    
                            <p className="text-stone-500 text-[16px] font-light font-roboto leading-tight tracking-tight">
                                {project.city}, {project.state}
                            </p>
                            <div className="flex flex-row space-x-[0.2rem]">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM18.36 16.83C16.93 15.09 13.46 14.5 12 14.5C10.54 14.5 7.07 15.09 5.64 16.83C4.62 15.49 4 13.82 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 13.82 19.38 15.49 18.36 16.83ZM8.5 9.5C8.5 7.56 10.06 6 12 6C13.94 6 15.5 7.56 15.5 9.5C15.5 11.44 13.94 13 12 13C10.06 13 8.5 11.44 8.5 9.5Z" fill="#666666"/>
                                </svg>
                                <p className="flex items-center text-stone-500 text-[15px] font-normal font-roboto leading-tight tracking-tight">
                                    Posted By Paul Nam, Associate Teaching Professor (CSE)
                                </p>
                            </div>

                            <div>
                                <div className="flex w-[155px] h-[24px] rounded-[100px] border border-zinc-500 justify-center items-center inline-flex mt-[0.6rem] sm:mt-[1.5rem]">
                                    <p className="text-center text-slate-500 text-xs font-medium font-roboto overflow-hidden text-overflow white-space-nowrap">Looking for {convertPartnerType(project.recruitment_type)}</p>
                                </div>
                            </div>
                            {/* Posted date - will need this in detailed card */}
                            {/* <div className='flex justify-between text-md mt-2'>
                                <div className="text-md">Posted: {convertMMDDYY(project.updated_at)}</div>
                                { eventExist && <div className="pl-[3rem]"><b>Involves Event</b></div>} 
                            </div> */}

                            {/* <div className='flex justify-evenly mt-2'>
                                <div>
                                    Looking for {convertPartnerType(project.recruitment_type)}
                                </div>
                                <div>|</div>
                                <div>
                                    {project.city}
                                </div>
                                <div>|</div>
                                <div>
                                <p>Due: {convertMMDDYY(project.end_date)}</p>

                                </div>
                            </div> */}
                            {/* Number of applications */}
                            {/* <div className="flex justify-center"> {project.application_count} Application{checkSinglePlural(project.application_count)} / {project.participant_num} Spot{checkSinglePlural(project.participant_num)} - {project.status}</div> */}
                        </div>
                    </div>       
                </div>
            </Link>
        </>
    );
}

// converting Looking for variable 
function convertPartnerType(project_type){
    let convertedType = 'Error'
    if (project_type === "find_mentor") {
        convertedType = "Mentor"
    } else if (project_type === "find_mentee" ) {
        convertedType = "Mentee"
    } else if (project_type === "find_team" ) {
        convertedType = "Team Member"
    }
    return convertedType
}

/**
 * The function converts ms to MM-DD-YY 
 * @param {*} timestamp 
 * @returns converted MM-DD-YYYY
 */
function timestampToDate(timestamp) {
    // Convert the timestamp to milliseconds by multiplying by 1000
    const date = new Date(timestamp * 1000);
    
    // Extract the month, day, and year from the date
    const month = date.toLocaleString('default', { month: 'short' });
    const day = date.getDate();
    const year = date.getFullYear();
    
    return `${month} ${day}, ${year}`;
}

function checkActive(app_enddate){
    const timestampInSeconds = Math.floor(Date.now() / 1000);
    let current_time = timestampInSeconds

    let status = 'error'
    if (app_enddate >= current_time){
        status = 'Active'
        return (
            <div className='px-2 border-2 border-purple-400 font-semibold'>
                {status}
            </div>
        )
        
    } else if (app_enddate < current_time) {
        status = "Inactive"
        return (
            <div className='px-2 border-2 border-blue-400 font-semibold'>
                {status}
            </div>
        )
    } else {
        return(
        <div className='px-2 border-2 border-red-400 font-semibold'>
            {status}
        </div>
        )
    }
}

