import react, { useState } from "react"

export default function SearchComponent({ setSearchTerm }) {
  const [isFocused, setIsFocused] = useState(false)

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value)
  }

  return (
    <div className="flex w-[85%] md:w-[100%] h-[32px] rounded-[28px] border border-zinc-500 items-center">
      <form className="lg:bottom-8 md:bottom-8 w-[100%]">
        <div className="flex flex-row">
          <div className="flex pl-[1rem]">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M3 8V6H21V8H3ZM3 13H21V11H3V13ZM3 18H21V16H3V18Z" fill="#49454F"/>
            </svg>
          </div>
          
          <input
              className="placeholder-zinc-700 text-base font-normal w-full pl-[0.9rem] bg-transparent focus:border-transparent focus:outline-none"
              type="text"
              placeholder="Hinted search text"
              onChange={handleInputChange}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
          />
          <div className="flex justify-end pr-[1rem]">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M14.76 13.27L20.49 19L19 20.49L13.27 14.76C12.2 15.53 10.91 16 9.5 16C5.91 16 3 13.09 3 9.5C3 5.91 5.91 3 9.5 3C13.09 3 16 5.91 16 9.5C16 10.91 15.53 12.2 14.76 13.27ZM9.5 5C7.01 5 5 7.01 5 9.5C5 11.99 7.01 14 9.5 14C11.99 14 14 11.99 14 9.5C14 7.01 11.99 5 9.5 5Z" fill="#49454F"/>
            </svg>
          </div>
        </div>
      </form>
    </div>
  )
}