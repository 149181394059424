import React from 'react';

// parameters not yet delivered from my-proposal, should be later implemented  -- jw

const AppPagination = ({ currentPage, totalPages, onPageChange }) => {
  const pages = [...Array(totalPages).keys()].map(i => i + 1);

  return (
    <div className="flex justify-center items-center">
      <button
        className={`mx-1 p-2 border border-black rounded ${currentPage === 1 ? 'opacity-50 cursor-not-allowed' : 'hover:bg-purple-400'}`}
        onClick={() => currentPage > 1 && onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
        Prev
      </button>

      {pages.map(page => (
        <button
          key={page}
          className={`mx-1 p-2 border border-black rounded ${page === currentPage ? 'bg-purple-400 text-white' : 'hover:bg-purple-400'}`}
          onClick={() => onPageChange(page)}
        >
          {page}
        </button>
      ))}

      <button
        className={`mx-1 p-2 border border-black rounded ${currentPage === totalPages ? 'opacity-50 cursor-not-allowed' : 'hover:bg-purple-400'}`}
        onClick={() => currentPage < totalPages && onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      >
        Next
      </button>
    </div>
  );
}

export default AppPagination;
