import React, { useState, useEffect } from 'react'
import AppPagination from './pagination'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { auth } from '../../../firebase/firebaseConfig'
import { useAuthState } from 'react-firebase-hooks/auth'
import InviteConfirmation from './inviteConfirmation'


function MyProposal({myproject}){
    //console.log(myproject)
    if (!myproject){
        setSelectedRowProject(null); 
    }
    
    const myproject_list = myproject.map(project => project.title)
    const [selectedRowIndex, setSelectedRowIndex] = useState(0);
    const [selectedRowProject, setSelectedRowProject] = useState(myproject[0])
    const [showConfirmation, setShowConfirmation] = useState(false)

    /* */
    const [applicationList, setApplicationList] = useState('')
    const [user, loading, error] = useAuthState(auth);
    const id_token = user.accessToken 
    const uid = user.uid
    const [showNotificationResult, setShowNotificationResult] = useState(false)
    const [notificationMessage, setNotificationMessage] = useState('')

    const NotificationResult = () => {
        // some kind of checking logic is required 
        // if success -> set tru, if now set false 
        setShowNotificationResult(true)
        setNotificationMessage('The invitation was successfully sent.')
    }

    useEffect(() => {
        // Do something immediately after selectedRowIndex changes
        //console.log(selectedRowIndex);
        setSelectedRowProject(myproject[selectedRowIndex]);
    }, [selectedRowIndex]); //dependency, only renders when there is an update with selectedRowIndex

    useEffect(() => {
        getApp(selectedRowProject);
    }, [selectedRowProject])

    // The finds the application from the given project 
    const getApp = async (selectedRowProject) => {
        const project_id = selectedRowProject.id
        const payload = {
          auth: {
            id_token: id_token, // here 
            uid: uid
          }, 
          data:{
            project_id: project_id
          }
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_SERVERURL}/application/get_applications`, payload)
            let applicationData = response.data.data
            //console.log(applicationData)
            setApplicationList(applicationData)
        } catch (err) {
          console.log(err)
            console.error('Error: ', err)
        }  
    }
    
    const emptyApplicationData = (['Empty', '-', '-', '-','-','-'])
    let applicationData = [];
    if (applicationList && applicationList.length > 0) {
        applicationData = applicationList.map(application => [
            application.id,
            application.resume_link,
            'link',
            application.reference_link, 
            timestampToDate(application.post_date), 
            null 
        ]);
    }

    const applicationCount = selectedRowProject?.application_count ?? 'Error';
    return(
        <>
            <div className='w-full'>
                <div className="max-w-1440 mx-auto"> {/* stopper */}
                <div className="flex flex-col sm:flex-row mx-0 sm:mx-10 mt-12">
                {/* left column */}
                <div className="w-full sm:w-3/12 flex flex-col">
                    {/* Choose Project */}
                    <div className='w-full flex flex-col items-center justify-center border border-gray-400 px-3 py-0 sm:py-7 mb-10 rounded-md'>
                        <div className='w-full text-xl font-semibold mb-1'>Choose Project</div>
                        { selectedRowProject ? 
                            (
                                <div className="max-h-80 overflow-y-scroll w-full">
                                    
                                    <table className="w-full my-3">
                                    <tbody>
                                            {myproject_list.map((project, index) => (
                                            <tr 
                                                key={index} 
                                                className={`hover:bg-gray-200 ${selectedRowIndex === index ? 'border border-black bg-gray-300' : ''}`} 
                                                onClick={() => {
                                                    setSelectedRowIndex(index);  // Updates the state
                                                }}
                                            >
                                                <td className="border border-black rounded-sm py-3 px-3   text-md font-semibold ">{project}</td>
                                            </tr>
                                            ))}
                                    </tbody>
                                    </table>
                                </div>
                            ) : 
                            (
                                <div className='text-center'> 
                                    No Submitted Project
                                </div>
                            )
                        }
                    </div>
                    
                    <div className='border border-gray-400 px-3 rounded-md py-7'>
                    <div className='mb-3 text-xl font-semibold text-center'>Project Information</div>
                    <div className='text'>
                        <table className="my-3 w-full text-center">
                        <tbody>
                            <tr>
                            <td className="bg-white text-md text-gray-800 py-2 border border-gray-400 rounded shadow my-1">
                                Status: <b>{selectedRowProject ? checkProjectStatus(selectedRowProject.app_close_date) : 'N/A'}</b>
                            </td>
                            </tr>
                            <tr>
                            <td className="bg-white text-md text-gray-800 py-2 border border-gray-400 rounded shadow my-1">
                                Application Closing: <b>{selectedRowProject ? timestampToDate(selectedRowProject.app_close_date) : 'N/A'}</b>
                            </td>
                            </tr>
                            {/*
                            <tr>
                            <td className="bg-white text-md text-gray-800 py-2 border border-gray-400 rounded shadow my-1">
                                Admission Type: <b>{selectedRowProject ? convertAppType(selectedRowProject.app_type)  : 'N/A'}</b>
                            </td>
                            </tr>
                            */} 
                            <tr>
                            <td className="bg-white text-md text-gray-800 py-2 border border-gray-400 rounded shadow my-1">
                                Post Due : <b>{selectedRowProject? timestampToDate(selectedRowProject.app_result_post_date) : 'N/A'}</b>
                            </td>
                            </tr>
                            <tr>
                            <td className="bg-white text-md text-gray-800 py-2 border border-gray-400 rounded shadow my-1">
                                View Full Description: <b>{selectedRowProject ?
                                    <Link to={`/proposal/${selectedRowProject.id}`}  target="_blank" rel="noopener noreferrer">
                                        Link
                                    </Link>: 'N/A'}</b>
                            </td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                </div>
                </div>

                {/* application column - right */}
                <div className="w-full sm:w-9/12 ml-0 sm:ml-5 sm:mt-0 mt-10">
                <div className='flex flex-col ml-0 sm:ml-5'>
                    <div className='border border-gray-400 rounded shadow mb-5 flex flex-col'>
                        <div className='font-semibold text-xl mt-3 mb-2 ml-5'>Application Statistics: </div>
                        <div className='flex flex-col sm:flex-row mb-3 ml-5 justify-evenly'>
                            <div>Received Applications : <b>[{applicationCount}]</b></div> | {/*  -- need better implementation*/ }
                            <div>Post Due : <b>{selectedRowProject? timestampToDate(selectedRowProject.app_result_post_date) : 'N/A'}</b></div>
                        </div>
                    </div>
                    <div className="hidden lg:block border border-gray-400 rounded shadow">
                        <div className="flex flex-col justify-evenly mx-5 py-3"> 
                            <div className='font-semibold text-xl my-3 ml-5'>
                                View Applications
                                <p className='text-sm'>
                                    Please do not use personal information for other purpose* 
                                </p>
                            </div>
                            
                            {/* show application for a chosen project*/ }
                            <table className=" mb-3 overflow-y-scroll">
                                <thead>
                                    <tr className="text-black broder-2 bg-gray-100">
                                    {['Application ID', 'Resume', 'View Application', 'Reference', 'Upload Date', 'Decision'].map((header, index) => (
                                        <th key={index} className="px-4 py-2">{header}</th>
                                    ))}
                                    </tr>
                                </thead>

                                {
                                // Check if applicationData is null or empty
                                (!applicationList || applicationList.length === 0) ? 
                                (<tbody className="h-[400px]">
                                <tr className='border-y-2 text-center hover:bg-gray-200 h-10'>
                                    {emptyApplicationData.map((data, index) => (
                                        <td key={index} className="px-4 py-2">{data}</td>
                                    ))}
                                </tr>
                                {/* Fill up with empty rows */}
                                {Array.from({ length: 7 }).map((_, index) => (
                                    <tr key={index + 8} className='border-y-2 h-10'></tr>
                                ))}
                                </tbody>)
                                   : 
                                    <tbody className="h-[400px]">
                                        {applicationData.map((row, rowIndex) => (
                                            <tr key={rowIndex} className='border-y-2  text-center hover:bg-gray-200 h-10'>
                                                {row.map((item, itemIndex) => (
                                                    <td key={itemIndex} className="px-4 py-2">
                                                        {itemIndex === 1 || itemIndex === 2 || itemIndex === 3 ? 
                                                            <a href={item} target="_blank" rel="noopener noreferrer">View</a> 
                                                            :itemIndex === 5 ?
                                                            <div>
                                                                <button onClick={() => setShowConfirmation(true)} className='text-md font-semibold hover:bg-purple-400 hover:text-white hover:border-black text-gray-800 py-1.5 px-5 mx-2 border border-gray-400 rounded shadow'> 
                                                                    Send Invitation
                                                                </button>
                                                                {showConfirmation && (
                                                                    <InviteConfirmation 
                                                                    onConfirm={() => {
                                                                        NotificationResult() // when yes is clicked, notification result is set to true
                                                                        console.log('Invite sent')
                                                                        // the modal won't close when "Yes" is clicked anymore since we've removed setShowConfirmation(false)
                                                                    }}
                                                                    onCancel={() => setShowConfirmation(false)} // this will close the modal when "No" is clicked or when the overlay is clicked
                                                                    />
                                                                )}
                                                            </div>
                                                        : item
                                                        }
                                                    </td>
                                                ))}
                                            </tr>
                                        ))}
                                        {/* Fill up with empty rows if necessary */}
                                        {Array.from({ length: 8 - applicationData.length }).map((_, index) => (
                                            <tr key={index + applicationData.length} className='border-y-2 h-10'></tr>
                                        ))}
                                    </tbody>
                                }
                            </table>
                            </div>
                            <div>
                                <AppPagination /> { /* view only, currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange}  need to be implemented for full functionality -- jw*/}

                            </div>
                            <div className='flex justify-end mx-5 my-5'>
                            <button className="bg-white text-md font-semibold hover:bg-purple-400 hover:text-white hover:border-black text-gray-800 py-2 px-5 mx-2 border border-gray-400 rounded shadow">
                                Save
                            </button>
                        </div>
                    </div>

                    {showNotificationResult && 
                        <div className='border border-gray-400 round-md text-xl mt-5 text-center py-3'>
                            {notificationMessage}
                        </div> 
                    }
                </div>
                </div>
                </div>
            </div>        
        <footer className='text-center'>
            &copy; 2023 Laryd. All Rights Reserved. 
        </footer>  
        </div>
        </>
    );
}


export default MyProposal; 



/* The followings are converting functions */ 

/**
 * the function convert app_type to appropriate client view 
 * return: converted text 
 */

function convertAppType(api_appType){
    let application_type = 'error'
    if(api_appType === "rolling") {
        application_type = "Rolling"
        return application_type
    } else if (api_appType === "due_date") {
        application_type = 'Due Date'
        return application_type
    } else {
        return application_type
    }
}

/*
 * the fuction compares current time and application post date, and define current project status  
 * @param {*} app_post_date 
 * @returns {status} 
 */

function checkProjectStatus(app_post_date){
    const timestampInSeconds = Math.floor(Date.now() / 1000);
    let new_postDate = timestampInSeconds

    let status = 'error'
    if (app_post_date >= new_postDate){
        status = 'On Time'
        return status
    } else if (app_post_date < new_postDate) {
        status = "Past Due"
        return status
    } else {
        return status 
    }
}

/**
 * The function converts ms to MM-DD-YY 
 * @param {*} timestamp 
 * @returns converted MM-DD-YYYY
 */
function timestampToDate(timestamp) {
    // Convert the timestamp to milliseconds by multiplying by 1000
    const date = new Date(timestamp * 1000);
    
    // Extract the month, day, and year from the date
    const month = date.toLocaleString('default', { month: 'short' });
    const day = date.getDate();
    const year = date.getFullYear();
    
    return `${month} ${day}, ${year}`;
}
