import ShareButton from "./ui/shareButton";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react"

function Proposal({ project, isFirst, showShareButton, setShowShareButton }) {
    const proposalLink = `/${project.id}`;

    const initialColor = isFirst ? 'bg-white' : 'bg-gray-200';  // If it is the first proposal, make the initial color white
    const [bgColor, setBgColor] = useState('bg-gray-200');  // Initial color
    const handleOnClick = () => {
        setBgColor(prevColor => prevColor === 'bg-gray-200' || prevColor === 'bg-white' ? 'bg-purple-400' : initialColor);  // Toggle color on click
    }

    // open social platforms at the current url
    const openTwitter = () => {
        const url = `https://twitter.com/share?url=${encodeURIComponent(window.location.href)}`;
        window.open(url, '_blank');
    }

    const openReddit = () => {
        const url = `https://www.reddit.com/submit?url=${encodeURIComponent(window.location.href)}`;
        window.open(url, '_blank');
    }

    const openLinkedIn = () => {
        const url = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(window.location.href)}`;
        window.open(url, '_blank');
    }

    const openFacebook = () => {
        const url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}`;
        window.open(url, '_blank');
    }

    useEffect(() => {
        if (showShareButton) {
            document.body.style.overflow = 'hidden';  // Disable scrolling
        } else {
            document.body.style.overflow = 'auto';    // Enable scrolling
        }

        return () => {
            document.body.style.overflow = 'auto';    // Re-enable scrolling on cleanup
        };
    }, [showShareButton]);

    if (!project) {
        return <div></div>
    }

    return(
        <>
            <Link to={proposalLink} key={project.id}>
                <div onClick={handleOnClick} className={`mb-4 mx-9 rounded-sm py-8 px-8 border-2 hover:bg-white ${bgColor}`}>
                    <div className='flex flex-col align-center'>
                        <div className='flex flex-row text-2xl justify-between'>

                            {/* for mobile only header takes to the details so that the user can click shared button */}
                                <b>{project.title.substring(0, 80)}</b>
                            {showShareButton && (
                                    <div 
                                        onClick={(e) => {
                                            setShowShareButton(false) // clicking the background makes showShareButton to false, so that the user can unlock from freezed screen
                                            document.body.style.overflow = 'auto'
                                            e.preventDefault()
                                        }} 
                                        className="fixed inset-0 flex justify-center items-center z-50 bg-black bg-opacity-20"
                                    >
                                    <div 
                                        onClick={(e) => e.stopPropagation()} 
                                        className="rounded p-4 bg-white flex space-x-4"
                                    >
                                        {/* Twitter */}
                                        <div onClick={openTwitter}>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="h-7 w-7"
                                                fill="currentColor"
                                                style={{color: "#1da1f2"}}
                                                viewBox="0 0 24 24"
                                            >
                                                <path
                                                    d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"
                                                />
                                            </svg>
                                        </div>

                                        {/* Reddit */}
                                        <div onClick={openReddit}>
                                            <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-7 w-7"
                                            fill="currentColor"
                                            style={{color: "#ff4500"}}
                                            viewBox="0 0 24 24">
                                            <path
                                                d="M24 11.779c0-1.459-1.192-2.645-2.657-2.645-.715 0-1.363.286-1.84.746-1.81-1.191-4.259-1.949-6.971-2.046l1.483-4.669 4.016.941-.006.058c0 1.193.975 2.163 2.174 2.163 1.198 0 2.172-.97 2.172-2.163s-.975-2.164-2.172-2.164c-.92 0-1.704.574-2.021 1.379l-4.329-1.015c-.189-.046-.381.063-.44.249l-1.654 5.207c-2.838.034-5.409.798-7.3 2.025-.474-.438-1.103-.712-1.799-.712-1.465 0-2.656 1.187-2.656 2.646 0 .97.533 1.811 1.317 2.271-.052.282-.086.567-.086.857 0 3.911 4.808 7.093 10.719 7.093s10.72-3.182 10.72-7.093c0-.274-.029-.544-.075-.81.832-.447 1.405-1.312 1.405-2.318zm-17.224 1.816c0-.868.71-1.575 1.582-1.575.872 0 1.581.707 1.581 1.575s-.709 1.574-1.581 1.574-1.582-.706-1.582-1.574zm9.061 4.669c-.797.793-2.048 1.179-3.824 1.179l-.013-.003-.013.003c-1.777 0-3.028-.386-3.824-1.179-.145-.144-.145-.379 0-.523.145-.145.381-.145.526 0 .65.647 1.729.961 3.298.961l.013.003.013-.003c1.569 0 2.648-.315 3.298-.962.145-.145.381-.144.526 0 .145.145.145.379 0 .524zm-.189-3.095c-.872 0-1.581-.706-1.581-1.574 0-.868.709-1.575 1.581-1.575s1.581.707 1.581 1.575-.709 1.574-1.581 1.574z" />
                                            </svg>
                                        </div>

                                        {/* Linkedin */}
                                        <div onClick={openLinkedIn}>
                                            <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-7 w-7"
                                            fill="currentColor"
                                            style={{color: "#0077b5"}}
                                            viewBox="0 0 24 24">
                                            <path
                                                d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z" />
                                            </svg>
                                        </div>

                                        <div onClick={openFacebook}>
                                            <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-7 w-7"
                                            fill="currentColor"
                                            style={{color: "#1877f2"}}
                                            viewBox="0 0 24 24">
                                            <path
                                                d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className='flex flex-row' onClick={(e) => {
                                e.preventDefault();
                                setShowShareButton(true);
                            }}><ShareButton /></div>
                        </div>
                        <p className="text-xl">{project.name}, {project.organization}</p>    
                        <div className='flex justify-between text-md mt-2'>
                            <div className="text-md">Posted: {convertMMDDYY(project.post_date)}</div>
                            {/*<div className="pl-[3rem]">Staked / Not Staked</div>*/} 
                            <div className="pl-[3rem]"><b>Involves Event</b></div> {/*Should become a parameter>*/} 

                        </div>

                        <div className='flex justify-evenly mt-2'>
                            <div>
                                Looking for {convertPartnerType(project.recruitment_type)}
                            </div>
                            <div>|</div>
                            <div>
                                {project.city}
                            </div>
                            <div>|</div>
                            <div>
                            <p>Due: {convertMMDDYY(project.end_date)}</p>

                            </div>
                        </div>
                    </div>       
                    {/* Number of applications */}
                    <div className="flex justify-center mt-5">{project.application_count} Application{checkSinglePlural(project.application_count)} / {project.participant_num} Spot{checkSinglePlural(project.participant_num)}</div>
                </div>       
            </Link>             
        </>
    );
}

export default Proposal; 

// converting unix time stamp to actual time
const convertMMDDYY = function unix_timeConverter(unix_timestamp) {
    var a = new Date(unix_timestamp * 1000)
    var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();                            
    var time = month + ' '+ date + ', ' + year;
    return time;
}


// converting Looking for variable 
function convertPartnerType(project_type){
    let convertedType = 'Error'
    if (project_type === "find_mentor") {
        convertedType = "Mentor"
    } else if (project_type === "find_mentee" ) {
        convertedType = "Mentee"
    } else if (project_type === "find_team" ) {
        convertedType = "Team Member"
    }
    return convertedType
}


function checkSinglePlural(count_input){
    let metric = ''
    if (count_input > 1){
        metric = '(s)'
        return metric
    } else {
        return metric 
    }
}