import logo from '../../img/layrd-logo.png'
import LoginHeader from './loginHeader'
import { Link, useNavigate } from "react-router-dom"
import { auth, provider, signInWithPopup } from '../../firebase/firebaseConfig'
import axios from 'axios'

function MainAuthentication(){
    const server_name = "https://api.layrd.xyz"

    const navigate = useNavigate();
    const signInWithGoogle = async () => {
        try {
            let user_cred = await signInWithPopup(auth, provider);
            let id_token = await user_cred.user.getIdToken();
            navigate('/user');

        } catch (error) {
            console.log(error.message);
        }
    };

    return(
        <>
            <div>
                <LoginHeader />
                <div className="max-w-1440 mx-auto"> {/* stopper */}
                    <div>
                        <b className='flex text-3xl justify-center mt-10'>Welcome to Layrd</b>   
                            <div className='flex flex-col border-2 w-full md:w-[50%] sm:w-[40%] items-center justify-center my-5 py-5 mx-auto'>       
                                <Link to="/signin">
                                    Already have an account? 
                                </Link>
                                <div onClick={signInWithGoogle} className='mt-5 flex-shrink-0 bg-white text-md hover:bg-gray-100 text-gray-800 py-2 px-3 border border-gray-400 rounded shadow type=myaccount'>
                                    Sign in with Google 
                                </div>
                            </div>
                    </div>
                </div>
            </div>
            <footer className='text-center'>
                &copy; 2023 Layrd. All Rights Reserved. 
            </footer>   
        </>
    ); 
}

export default MainAuthentication; 