import ApplyResearchDescription from './expectation';
import ApplyResearchResponse from './response';
import ApplyHeader from '../main/header';
import { auth } from '../../firebase/firebaseConfig';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Link, useParams } from  "react-router-dom"; 
import { useState, useRef, useEffect } from "react";
import RegButton from './RegButton';

function Apply({ sortedProjects, projectLoading }){
    const [user, loading, error] = useAuthState(auth)
    // apply page - starting from page 1
    const { id } = useParams()

    const proj_list = sortedProjects?.map((project) => project)
    const projects = proj_list.find((project) => project.id === parseInt(id)) 

    const [currentStep, setCurrentStep] = useState(1);
    const [lineWidth, setLineWidth] = useState(0);
    const [lineOffset, setLineOffset] = useState(0);
    const stepRefs = useRef([]);

    useEffect(() => {
        if (stepRefs.current[currentStep-1]) {
            setLineWidth(stepRefs.current[currentStep-1].offsetWidth);
            setLineOffset(stepRefs.current[currentStep-1].offsetLeft);
        }
    }, [currentStep]);

    // directly set the current step to the clicked step
    const goToStep = (step) => setCurrentStep(step);

    // function to handle signout
    const handleSignout = async () => {
        try {
            await auth.signOut();
        } catch (err) {
            console.error("Signout Error", err);
        }
    };

    if (loading || projectLoading) {
        <div></div>
    }

    if (!projects) {
        return <div></div>;
    }

     if (error) {
        return <div>Error: {error.message}</div>;
    }

    const steps = [
        { number: 1, label: 'Overview' },
        { number: 2, label: 'Application' },
    ];

    const renderStepNavigation = () => {
        return (
            <>
                <div className="flex flex-col w-[70%] relative">
                    <div className="flex justify-start space-x-4">
                        {steps.map((step, index) => (
                            <button
                                key={step.number}
                                ref={el => stepRefs.current[index] = el}
                                onClick={() => goToStep(step.number)}
                                className={`text-[16px] font-medium font-roboto ${currentStep === step.number ? 'text-black' : 'text-[#A3A2A4]'}`}
                            >
                                {step.label}
                            </button>
                        ))}
                    </div>
                    <div className="relative">
                        <hr className="absolute bg-[#6750A4] h-[3px]" style={{ width: `${lineWidth}px`, marginLeft: `${lineOffset}px` }} />
                        <hr className="bg-[#DFDFEF] h-[3px] w-full" />
                    </div>
                </div>
            </>
        );
    };

    const renderStep = () => {
        switch(currentStep) {
          case 1:
            return (
                <>
                    <div className='flex justify-center sm:w-[100%] w-[0%] mt-[2rem]'>
                        <div className="flex justify-center items-center sm:w-[70%]">
                            <ApplyResearchDescription projects={proj_list} />
                        </div>
                    </div>
                </>
            );
          case 2:
            return (
                <>
                    <div className='flex justify-center sm:w-[100%] w-[0%] mt-[2rem]'>
                        <div className="flex justify-center items-center sm:w-[70%]">
                            <ApplyResearchResponse  projects={proj_list} /> 
                        </div>
                    </div>
                </>
            );
        }
    };

    return(
        <>
            <div className="pt-[3rem]">
                <ApplyHeader />
            </div>
            <div className="flex justify-center w-[100%] mt-[4.5rem]">
                <div className="flex justify-start w-[70%] font-bold text-[22px] font-roboto">
                    Apply
                </div>
            </div>
            <div className="flex justify-center mt-[3rem]">
                {renderStepNavigation()}
            </div>
            {renderStep()}

            <div className="flex justify-center space-x-2 mt-4">
            {/* always show "Previous" button if not on the first step */}
            {currentStep > 1 && (
                < RegButton type="button" text="Previous" onClick={() => goToStep(currentStep - 1)} />
            )}

            {/* show "Next" button if on the first step */}
            {currentStep === 1 && (
                < RegButton type="button" text="Next" onClick={() => goToStep(2)} />
            )}

            {/* on the last step, decide action based on user login state */}
            {currentStep === 2 && (
                user ? (
                    < RegButton type="Submit" text="Submit" form="applyResearchForm" />
                ) : (
                    // show "Sign In" link if user is not logged in
                    <Link to="/auth/apply">
                        < RegButton text="Sign In" />
                    </Link>
                )
            )}
            </div>
            <footer className='text-center'>
                    &copy; 2023 Laryd. All Rights Reserved. 
            </footer>  
        </>
    ); 
}

export default Apply; 